export enum SeverityLevel {
    Info = 0,
    Low = 1,
    Moderate = 2,
    High = 3,
    Severe = 4,
}

export type ClientSeverityTableRowData = {
    name?: string;
    category: {
        level1: string;
        level2: string;
        level3: string;
    };
    severity: SeverityLevel;
    defaultSeverity: SeverityLevel;
};

export type ClientSeverityTableData = ClientSeverityTableRowData[];
