import { Box, Breakpoints, ButtonText, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { SwitcherBanner } from "../../components/ClientSwitcher/SwitcherBanner";
import { ORGANIZATION_ADMIN_ROLE } from "../../models/RoleModel";
import { useGetUserClientRoleNames } from "../../store/clientSwitcher/ClientSwitcherHooks";
import { useDisplaySwitcherBanner } from "../../store/clientSwitcher/ClientSwitcherHooks";
import { useAncestorSiteGroups } from "./hooks/SitesGroupsHooks";
import { SitesBreadcrumbs } from "./SitesBreadcrumbs";
import { ContainerStyled } from "./SitesPage.styles";
import { SwitcherBannerWrapperStyled, TitleStyled } from "./SitesPageHeader.styles";

type Props = {
    selectedGroupId: string;
    onGroupSelect: (groupId: string) => void;
};

export const SitesPageHeader = ({ selectedGroupId, onGroupSelect }: Props) => {
    const { t } = useTranslation();
    const userClientRoleNames = useGetUserClientRoleNames();
    const selectedGroupAncestors = useAncestorSiteGroups(selectedGroupId);
    const isSwitcherBannerVisible = useDisplaySwitcherBanner();
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    const isEditButtonVisible = userClientRoleNames.includes(ORGANIZATION_ADMIN_ROLE) && !isMobile;

    const handleEditGroupsClick = () => {
        window.top.location.href = `${process.env.REACT_APP_WEB_HUB_GLOBAL_URL}/groups`;
    };

    const renderEditButton = () => (
        <ButtonText icon="Edit" onClick={handleEditGroupsClick}>
            {t("sites.list.editGroups")}
        </ButtonText>
    );

    return (
        <ContainerStyled>
            {isSwitcherBannerVisible && (
                <SwitcherBannerWrapperStyled>
                    <SwitcherBanner />
                </SwitcherBannerWrapperStyled>
            )}
            <Box marginVertical="M">
                <TitleStyled data-testid="sites-header">{t("sites.sites")}</TitleStyled>
                <Stack marginTop="S" justifyContent="space-between" alignItems="center">
                    <SitesBreadcrumbs selectedGroupAncestors={selectedGroupAncestors} onGroupSelect={onGroupSelect} isEditButtonVisible={isEditButtonVisible} />
                    {isEditButtonVisible && renderEditButton()}
                </Stack>
            </Box>
        </ContainerStyled>
    );
};
