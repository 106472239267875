import { Navigation, Spacing, Stack, TooltipRich } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const NavigationStyled = styled(Navigation)`
    div[hidden] {
        display: none;
    }
`;

export const NavigationLinkWrapperStyled = styled(Stack).attrs({
    mt: "L",
    gap: "L",
})`
    position: relative;

    & > a {
        width: 100%;
    }
`;

export const TooltipRichStyled = styled(TooltipRich)`
    margin-left: ${Spacing.XS}px;
`;
