import { Breakpoints, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { useAutoTranslationWhenNotTranslated } from "../../../helpers/autoTranslations/hooks";
import { DefaultItemField, Direction, TextValueStyled, ValueAlignment } from "../DefaultItemField";
import type { TagProps } from "./Tag";

export type TextSummaryFieldProps = PropsWithChildren<{
    fieldName: string;
    fieldValue?: string | number;
    tag?: TagProps;
    isRequired?: boolean;
    allowEmptyValue?: boolean;
    shouldAutoTranslate?: boolean;
    desktopDirection?: Direction;
    mobileDirection?: Direction;
    mobileValueAlignment?: ValueAlignment;
    desktopValueAlignment?: ValueAlignment;
}>;

export const TextSummaryField = ({
    fieldName,
    fieldValue,
    tag,
    desktopDirection = "row",
    mobileDirection = "row",
    shouldAutoTranslate = true,
    isRequired = true,
    allowEmptyValue = false,
    mobileValueAlignment,
    desktopValueAlignment,
    children,
}: TextSummaryFieldProps): JSX.Element => {
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { translatedText: textValue } = useAutoTranslationWhenNotTranslated(fieldValue, shouldAutoTranslate);
    const { translatedText: labelText } = useAutoTranslationWhenNotTranslated(fieldName, shouldAutoTranslate);

    if (!allowEmptyValue && !isRequired && !fieldValue) {
        return null;
    }

    const [direction, valueAlignment] = isMobile ? [mobileDirection, mobileValueAlignment] : [desktopDirection, desktopValueAlignment];

    return (
        <DefaultItemField label={labelText} tag={tag} alignItems={direction === "row" ? "center" : undefined} direction={direction}>
            <Stack style={{ alignItems: "inherit" }} gap="M" justifyContent={valueAlignment === "end" ? "flex-end" : "flex-start"}>
                {children}
                {textValue || !allowEmptyValue ? <TextValueStyled>{textValue || t("common.noDataFound")}</TextValueStyled> : null}
            </Stack>
        </DefaultItemField>
    );
};
