import { Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { TableCellProps } from "src/components/shared/Table/Table.types";
import { TourStatus } from "src/components/shared/TourStatus";
import { PatrolTourType } from "src/models/ReportModel";

type Props = TableCellProps<number, PatrolTourType>;

export const MissedCheckpointsCell = ({ cell }: Props) => {
    const { t } = useTranslation();

    if (!cell) {
        return (
            <Text micro bold>
                -
            </Text>
        );
    }

    return <TourStatus color="high">{`${cell} ${t("reportdetails.patrolTour.missed")}`}</TourStatus>;
};
