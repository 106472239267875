import { Breakpoints, Spacing } from "@secuis/ccp-react-components";
import { Virtuoso } from "react-virtuoso";
import styled from "styled-components";

import { ReportsListItemType } from "../../../models/ReportModel";
import { VirtualListContext } from "../Reports.types";

export const RelativeWrapper = styled.div`
    position: relative;
    z-index: 1;
`;

export const VirtuosoStyled = styled(Virtuoso<ReportsListItemType, VirtualListContext>)<{ isScrollBlocked?: boolean }>`
    height: 100%;
    flex: 1;

    ${(props) =>
        props.isScrollBlocked &&
        `overflow: hidden !important;
    `};

    & > div {
        padding: 0 40px;

        @media (max-width: ${Breakpoints.M}) {
            padding: 0 ${Spacing.M}px;
        }

        @media (max-width: ${Breakpoints.XS}) {
            padding: 0;
        }
    }
`;
