import { Breakpoints, EmptyState, LoadingIndicator, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { Modal } from "src/components/shared/Modal/Modal";
import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";

import { DraggableGrid } from "./DraggableGrid/DraggableGrid";
import { GRID_WIDGETS } from "./Summary.constants";
import { useSummary, useSurvicateInSummary } from "./Summary.hooks";
import { SummaryGrid } from "./SummaryGrid";
import { SummaryHeader } from "./SummaryHeader";

export const Summary = () => {
    const {
        gridDefaultLayouts,
        isGridReady,
        isCustomized,
        isEditMode,
        isResetModalOpen,
        isLoading,
        hasData,
        toggleResetModal,
        toggleEditMode,
        handleSettingsSave,
        handleSettingsReset,
        handleGridLayoutChange,
    } = useSummary();
    const { handleUserInteraction } = useSurvicateInSummary();
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const isCustomizable = useFeatureFlag(featureFlags.personalizeKpiSummary);

    const renderContent = (isLoading: boolean, hasData: boolean, isGridReady: boolean) => {
        if (isLoading) {
            return (
                <Stack flex="1" justifyContent="center" pv="XXXL">
                    <LoadingIndicator size="L" />
                </Stack>
            );
        }

        if (!hasData) {
            return (
                <Stack flex="1" justifyContent="center" pv="XL">
                    <EmptyState icon="GraphIndicator" title={t("common.noDataToDisplay")} subtitle={t("insights.summary.emptyState.label")} />
                </Stack>
            );
        }
        if (isGridReady) {
            return (
                <DraggableGrid
                    isEditable={isEditMode}
                    widgets={GRID_WIDGETS}
                    defaultLayouts={gridDefaultLayouts}
                    defaultLayoutConfig={GRID_WIDGETS}
                    onLayoutChange={handleGridLayoutChange}
                />
            );
        }
    };

    return (
        <div onMouseMove={isMobile ? undefined : handleUserInteraction} onTouchStart={isMobile ? handleUserInteraction : undefined}>
            <SummaryHeader
                isEditMode={isEditMode}
                isCustomized={isCustomized}
                toggleEditMode={toggleEditMode}
                onSettingsSave={handleSettingsSave}
                onSettingsReset={toggleResetModal}
                isDisabled={!hasData || isLoading}
            />
            {isCustomizable ? renderContent(isLoading, hasData, isGridReady) : <SummaryGrid />}
            {isResetModalOpen && (
                <Modal
                    actions={[
                        {
                            children: t("insights.trendingEvents.resetModal.resetToDefault"),
                            onClick: handleSettingsReset,
                        },
                        {
                            children: t("common.cancel"),
                            onClick: toggleResetModal,
                        },
                    ]}
                    isOpen={isResetModalOpen}
                    onClose={toggleResetModal}
                    size="S"
                    title={t("insights.summary.settings.resetModal.title")}
                >
                    <Text>
                        <p>{t("insights.summary.settings.resetModal.description.1")}</p>
                        <p>{t("insights.summary.settings.resetModal.description.2")}</p>
                    </Text>
                </Modal>
            )}
        </div>
    );
};
