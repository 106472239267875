import { Breakpoints, Spacing, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { HorizontalLine } from "src/components/shared/HorizontalLine";
import { QaProps } from "src/models";

import { Widget } from "../shared/components";
import { ValueTileStatic } from "../shared/components/ValueTileStatic";
import { SEPARATOR_COLOR } from "../Summary.constants";
import { SummaryGridLayoutStyled } from "../SummaryGrid/SummaryGrid.styles";

type Props = QaProps;

export const PerformanceIndicatorsWidgetStatic = (props: Props) => {
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    return (
        <Widget {...props} hasHeaderSeparator={false} title={t("insights.summary.performanceIndicators.title")}>
            <SummaryGridLayoutStyled>
                <ValueTileStatic icon="Route" title={t("insights.summary.performanceIndicators.completedTours")} label={t("common.tours", { count: 0 })} />
                {isMobile && <HorizontalLine color={SEPARATOR_COLOR} marginTop={0} marginBottom={Spacing.M} />}
                <ValueTileStatic icon="Route" title={t("insights.summary.performanceIndicators.uncompletedTours")} label={t("common.tours", { count: 0 })} />
            </SummaryGridLayoutStyled>
        </Widget>
    );
};
