import { Box, Breakpoints, Spacing, Tabs } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const WrapperStyled = styled.div`
    flex-grow: 1;
    overflow: auto;

    @media (max-width: ${Breakpoints.L}) {
        overflow: unset;
    }
`;

export const TabsStyled = styled(Tabs).attrs({ scrollBehavior: "instant" })`
    button:focus-visible {
        outline-offset: -4px !important;
        border-radius: 4px !important;
    }
`;

export const OutletWrapperStyled = styled(Box)`
    height: 100%;
`;
