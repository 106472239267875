import { ChangeEvent, KeyboardEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { MixpanelEvent } from "src/models/tracking/mixpanelEvents";
import TrackingActions from "src/store/tracking/TrackingActions";

interface UseSearchProps {
    onChange: (value: string) => void;
    submitSearch: (value?: string) => void;
}

export const useSearch = ({ onChange, submitSearch }: UseSearchProps) => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState("");
    const [hasStartedTyping, setHasStartedTyping] = useState(false);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event?.target) return;

        setSearchValue(event.target.value);
        onChange(event.target.value);
    };

    const clearSearchResults = () => {
        setSearchValue("");
        setHasStartedTyping(false);
        onChange("");
        submitSearch("");

        dispatch(TrackingActions.trackEvent(MixpanelEvent.SeverityLevelsSearchClear));
    };

    const handleClear = () => {
        clearSearchResults();
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (!hasStartedTyping) {
            setHasStartedTyping(true);

            dispatch(TrackingActions.trackEvent(MixpanelEvent.SeverityLevelsSearchStartTyping, { key: event.key }));
        }

        if (event.key === "Enter") {
            submitSearch();

            dispatch(TrackingActions.trackEvent(MixpanelEvent.SeverityLevelsSearchSubmit, { searchValue }));
        }
    };

    const handleSuggestionClick = (item: string) => {
        if (!item) return;

        onChange(item);
        submitSearch(item);

        dispatch(TrackingActions.trackEvent(MixpanelEvent.SeverityLevelsSearchSelectSuggestion, { suggestion: item }));
    };

    return {
        handleChange,
        handleClear,
        handleKeyDown,
        handleSuggestionClick,
    };
};
