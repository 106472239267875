import { Background, Spacing, Stack, Theme } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components";

import { TooltipRich } from "../../../../../components/shared/TooltipRich";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const shakingAnimation = css`
    @keyframes jiggle1 {
        0% {
            transform: rotate(-0.125deg);
            animation-timing-function: ease-in;
        }

        50% {
            transform: rotate(0.25deg);
            animation-timing-function: ease-out;
        }
    }

    @keyframes jiggle2 {
        0% {
            transform: rotate(0.125deg);
            animation-timing-function: ease-in;
        }

        50% {
            transform: rotate(-0.25deg);
            animation-timing-function: ease-out;
        }
    }

    &:nth-child(2n) > * {
        animation: jiggle1 1000ms ease infinite alternate;
        transform-origin: 50% 3%;
    }

    &:nth-child(2n-1) > * {
        transform-origin: 40% 50%;
        animation: jiggle2 1000ms ease infinite alternate;
    }
`;

export const ContainerStyled = styled.div<{
    shaking?: boolean;
}>`
    ${({ shaking }) => (shaking ? shakingAnimation : "")};
`;

export const WrapperStyled = styled(Stack).attrs({
    direction: "column",
})<{
    isDisabled?: boolean;
    isDragged?: boolean;
    isEditable?: boolean;
}>`
    ${({ isDisabled }) => `
        background: ${isDisabled ? Background.disabled : Background.content};
    `}

    position: relative;
    border-radius: ${Spacing.XXS}px;
    width: 100%;
    height: 100%;

    ${({ isEditable }) => (isEditable ? "cursor: grab" : "")};
    ${({ isEditable, isDragged }) => (isEditable && isDragged ? "cursor: grabbing" : "")};
    ${({ isEditable, isDisabled }) => (isEditable && isDisabled ? "cursor: default" : "")};

    &::after {
        ${({ isEditable }) => (isEditable ? `content: "";` : "")}
        position: absolute;
        border-radius: inherit;
        height: 100%;
        width: 100%;
        top: 0;
        z-index: 1;
    }
`;

export const ToolBarStyled = styled(Stack).attrs({
    mh: "S",
    mt: "S",
    justifyContent: "flex-end",
})`
    z-index: 3;

    & + * {
        margin-top: -${Spacing.XS}px; // widget's padding is S but spacing between toolbar and the content needs to be XS.
    }
`;

export const ContentWrapperStyled = styled(Stack).attrs({
    flex: 1,
    direction: "column",
})<{ isDisabled?: boolean; isBlurred?: boolean }>`
    ${({ isBlurred }) => (isBlurred ? "filter: blur(3px);" : "")}
    // NOTE: Consider creating custom, disabled variant for charts,
    // instead of overwriting styles
    ${({ isDisabled }) =>
        isDisabled &&
        `
        & * {
            color: ${Theme.disabledText};
        }

        .recharts-wrapper {
            path, circle, line {
                fill: ${Theme.disabled};
                stroke: ${Theme.disabled};
            }
            text {
                fill: ${Theme.disabledText};
            }
        }
    `}
`;

export const DraggingAddonStyled = styled(Stack).attrs({
    justifyContent: "center",
    alignItems: "center",
})`
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
`;

export const TooltipStyled = styled(TooltipRich).attrs({
    borderRadius: "XXS",
})`
    &.custom-tooltip {
        width: auto;
        padding: ${Spacing.XXS}px ${Spacing.XS}px;
    }
`;
