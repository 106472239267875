import { useTranslation } from "react-i18next";

import { useFilteredSites } from "../../../../store/insights/FilterHooks";
import { useAuthorizedLocations } from "../../../../store/locations/LocationsHooks";
import { useCombinedReportsAndDeviations, useSummaryPeriod } from "../shared/hooks";
import { StatisticData } from "../shared/types";

export const useReportsCountWidget = () => {
    const { t } = useTranslation();
    const { siteObjects } = useAuthorizedLocations();
    const { siteIds } = useFilteredSites();
    const selectedSitesCount = siteIds.length ?? siteObjects?.length ?? 0;
    const { currentPeriod, previousPeriod } = useSummaryPeriod();
    const { reportsCountData: currentPeriodValues, isLoading: isLoadingCurrent } = useCombinedReportsAndDeviations(currentPeriod.start, currentPeriod.end);
    const { reportsCountData: previousPeriodValues, isLoading: isLoadingPrevious } = useCombinedReportsAndDeviations(previousPeriod.start, previousPeriod.end);

    const isLoading = isLoadingCurrent || isLoadingPrevious;

    const sitesWithoutReportsCount = {
        current: selectedSitesCount < currentPeriodValues.sitesCount ? 0 : selectedSitesCount - currentPeriodValues.sitesCount,
        previous: selectedSitesCount < previousPeriodValues.sitesCount ? 0 : selectedSitesCount - previousPeriodValues.sitesCount,
    };

    const countStats: StatisticData[] = [
        {
            id: "reports-count",
            title: t("insights.summary.reportsCountWidget.reportsCount"),
            label: t("common.reports", { count: currentPeriodValues.reportsCount }),
            value: currentPeriodValues.reportsCount,
            previousValue: previousPeriodValues.reportsCount,
        },
        {
            id: "sites-with-reports",
            title: t("insights.summary.reportsCountWidget.sitesWithReports"),
            label: t("common.sites", { count: currentPeriodValues.sitesCount }),
            value: currentPeriodValues.sitesCount,
            previousValue: previousPeriodValues.sitesCount,
        },
        {
            id: "sites-without-reports",
            title: t("insights.summary.reportsCountWidget.sitesWithoutReports"),
            label: t("common.sites", { count: sitesWithoutReportsCount.current }),
            value: sitesWithoutReportsCount.current,
            previousValue: sitesWithoutReportsCount.previous,
        },
    ];

    return { isLoading, countStats };
};
