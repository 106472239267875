export enum MixpanelEvent {
    AppVisit = "App visited",
    PageView = "Page View",
    InsightsLocationFilterApply = "Insights Filter By Location Applied",
    ReportListFilterOpened = "Report List Filter Opened",
    ReportListFilterApply = "Report List Filter Applied",
    ShowUnreadFilterApply = "Show Unread Filter Applied",
    CustomizeTop5IncidentsOpen = "Customize Top 5 Incidents Opened",
    CustomizeTop5IncidentsApply = "Customize Top 5 Incidents Applied",
    CustomizeTop5IncidentsReset = "Customize Top 5 Incidents Reset",
    ReportsAutoTranslateEnable = "Reports Auto Translate Enabled",
    ReportsAutoTranslateDisable = "Reports Auto Translate Disabled",
    InsightsTabView = "Insights Tab View",
    SeverityLevelsTabView = "Severity Levels Tab View",
    SeverityLevelsSearchSelectSuggestion = "Severity Levels Search Select Suggestion",
    SeverityLevelsSearchStartTyping = "Severity Levels Search Start Typing",
    SeverityLevelsSearchClear = "Severity Levels Search Clear",
    SeverityLevelsSearchSubmit = "Severity Levels Search Submit",
}
