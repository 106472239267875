import { Spacing, Stack } from "@secuis/ccp-react-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { HorizontalLine } from "src/components/shared/HorizontalLine";
import { QaProps } from "src/models";

import { REPORT_CATEGORY_ICON_MAP } from "../../shared/constants";
import { CategoriesDropdown, Widget } from "../shared/components";
import { ValueTileStatic } from "../shared/components/ValueTileStatic";
import { SUMMARY_EVENT_CATEGORIES } from "../shared/constants";
import { SEPARATOR_COLOR } from "../Summary.constants";

type Props = QaProps & {
    isDisabled?: boolean;
};

export const IncidentCategoriesWidgetStatic = (props: Props) => {
    const { t } = useTranslation();

    return (
        <Widget
            {...props}
            title={t("insights.summary.incidentCategoriesWidget.title")}
            headerActions={!props.isDisabled && <CategoriesDropdown />}
            hasHeaderSeparator={false}
        >
            <Stack direction="column" flex={1}>
                {SUMMARY_EVENT_CATEGORIES.map((categoryKey, index) => (
                    <React.Fragment key={index}>
                        {index !== 0 && <HorizontalLine color={SEPARATOR_COLOR} marginTop={0} marginBottom={Spacing.M} />}
                        <ValueTileStatic
                            icon={REPORT_CATEGORY_ICON_MAP[categoryKey]}
                            label={t("common.event", { count: 0 })}
                            title={t(`incident.category.${categoryKey}`)}
                            showRedirectIcon
                        />
                    </React.Fragment>
                ))}
            </Stack>
        </Widget>
    );
};
