import { useTranslation } from "react-i18next";

import { EmptyStateStyled, StackStyled } from "./ScreenSizeNotSupported.styles";

export const ScreenSizeNotSupported = () => {
    const { t } = useTranslation();

    return (
        <StackStyled p="XXL" justifyContent="center">
            <EmptyStateStyled
                icon="WarningHigh"
                title={t("settings.clientSeverityLevel.notSupported.title")}
                subtitle={t("settings.clientSeverityLevel.notSupported.subtitle")}
                data-testid="settings-csl-not-supported"
            />
        </StackStyled>
    );
};
