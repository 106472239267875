import { mapValues } from "lodash";
import { parseSqliteResult } from "src/helpers/parseSqliteResult";
import { WorkerExecResult } from "src/models/DbModel";
import { getParameterizedArray, getReportQueryTimeRangeCondition, getSqlStringifiedArrayFromObject } from "src/sql/scripts/queryUtilities";

export const getReportsCountQuery = ({ siteIds, startDate, endDate }: { siteIds: string[]; startDate: Date; endDate: Date }) => {
    const siteIdsParams = getParameterizedArray(siteIds);
    const stringifiedSites = getSqlStringifiedArrayFromObject(siteIdsParams);

    return {
        sql: `
            SELECT location_id as locationId, count(*) as reportsCount
            FROM reports
            WHERE
                category_level1 IS NOT NULL
                AND category_level1 <> ""
                AND ${getReportQueryTimeRangeCondition(startDate, endDate)}
                AND location_id IN (${stringifiedSites})
            GROUP BY location_id
        `,
        params: {
            ...siteIdsParams,
        },
    };
};

export const parseReportsCountResult = (execResult: WorkerExecResult | null) => {
    const resultData = execResult?.results?.[0];

    return mapValues(parseSqliteResult(resultData, true), "reportsCount");
};
