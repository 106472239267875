import { Box, Stack, Table } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import type { ClientSeverityTableRowData, SeverityLevel as SeverityLevelType } from "../../../ClientSeverityLevel.types";
import { highlightText } from "../../../helpers/highlightText";
import { SeverityLevel } from "../../SeverityLevel";
import { SeverityLevelSwitcher } from "../../SeverityLevelSwitcher";
import CategoryIcon from "./CategoryIcon";
import { TextStyled } from "./TableRow.styles";

type Props = ClientSeverityTableRowData & {
    isEditable?: boolean;
    searchValue?: string;
    onSeverityChange?: (category: string, severity: SeverityLevelType) => void;
};

const TableRow = ({ category, severity, defaultSeverity, isEditable, searchValue, onSeverityChange }: Props) => {
    const { t } = useTranslation();

    const formatCategory = (categoryLevel2?: string, categoryLevel3?: string): string => {
        return [categoryLevel2, categoryLevel3]
            .filter(Boolean)
            .map((level) => t(`incident.category.${level}`))
            .join(" . ");
    };

    const handleSeverityChange = (severityLevel: SeverityLevelType) => {
        onSeverityChange?.(category.level3, severityLevel);
    };

    const highlightedText = highlightText(formatCategory(category.level2, category.level3), searchValue);

    return (
        <>
            <Table.Td>
                <Stack>
                    <Box mr="XS">
                        <CategoryIcon category={category.level1} tooltipMessage={t(`incident.category.${category.level1}`)} />
                    </Box>
                    {highlightedText.map(({ chunk, highlighted }, index) => (
                        <TextStyled key={index} isHighlighted={highlighted}>
                            {chunk.replace(/ /g, "\u00A0")}
                        </TextStyled>
                    ))}
                </Stack>
            </Table.Td>
            <Table.Td>
                {isEditable ? (
                    <SeverityLevelSwitcher severityLevel={severity} defaultSeverityLevel={defaultSeverity} onSeverityChange={handleSeverityChange} />
                ) : (
                    <SeverityLevel severityLevel={severity} />
                )}
            </Table.Td>
        </>
    );
};

export default TableRow;
