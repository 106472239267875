import { Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const WrapperStyled = styled(Stack).attrs({
    direction: "column",
    pt: "S",
})`
    height: 100%;
`;

export const ColumnsWrapperStyled = styled(Stack).attrs({
    gap: "S",
    mb: "L",
})<{ showTwoColumns: boolean }>`
    flex-grow: 1;
    flex-direction: ${({ showTwoColumns }) => (showTwoColumns ? "row" : "column-reverse")};
    overflow: auto;

    & > {
        &:nth-child(1) {
            flex: 1;
        }

        &:nth-child(2) {
            width: ${({ showTwoColumns }) => (showTwoColumns ? "30%" : "auto")};
        }
    }
`;
