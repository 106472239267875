import { useSelector } from "react-redux";
import { formatToRawDate } from "src/helpers/date";
import { useDebouncedApiRequest } from "src/hooks/accessApi";
import InsightsSelectors from "src/store/insights/InsightsSelectors";

import { CategoryIncidentCountPerSite, DeviationsAPIData, FetchDeviationsAPIResponse } from "../types";

type UseFetchDeviationsResult = {
    deviations: CategoryIncidentCountPerSite;
    isLoading: boolean;
};

export const useFetchDeviations = (siteIds: string[], categories: string[], level: number, startDate?: Date, endDate?: Date): UseFetchDeviationsResult => {
    const startDateISO = useSelector(InsightsSelectors.getSelectedStartDate).toISOString();
    const endDateISO = useSelector(InsightsSelectors.getSelectedEndDate).toISOString();

    const selectedStartDate = startDate ?? startDateISO;
    const selectedEndDate = endDate ?? endDateISO;

    const { data, isLoading } = <FetchDeviationsAPIResponse>(
        useDebouncedApiRequest("/v2/global/insights/tour-exc/monthly-location-category-summary", "POST", 50, {
            locationIds: siteIds,
            categories: categories.map((category) => {
                return {
                    category,
                    level,
                };
            }),
            fromDate: formatToRawDate(selectedStartDate),
            toDate: formatToRawDate(selectedEndDate),
        })
    );

    const deviations: CategoryIncidentCountPerSite = Array.isArray(data) ? groupDeviationsByLocationAndCategory(data) : {};

    return {
        deviations,
        isLoading,
    };
};

const groupDeviationsByLocationAndCategory = (deviations: DeviationsAPIData): CategoryIncidentCountPerSite => {
    return deviations.reduce((acc, incident) => {
        const { locationId, category, count } = incident;

        if (!acc[locationId]) {
            acc[locationId] = {};
        }

        if (!acc[locationId][category]) {
            acc[locationId][category] = 0;
        }

        acc[locationId][category] += count;

        return acc;
    }, {});
};
