import { useCallback, useEffect, useMemo, useState } from "react";
import type { Layouts } from "react-grid-layout";
import { featureFlags } from "src/data/featureFlags";
import { SURVICATE_EVENTS, useSurvicate } from "src/helpers/survicate";
import { useFeatureFlag } from "src/hooks/featureFlags";
import { UserPreference } from "src/models/UserModel";
import type { SummaryViewMode } from "src/store/insights/InsightsModel";
import { useSetUserPreference, useUserPreference } from "src/store/user/UserHooks";

import { useHasData, useSummaryViewMode } from "./shared/hooks";
import { deserializeSettings, serializeSettings } from "./Summary.helpers";
import type { SummarySettings } from "./Summary.types";

export const useSummarySettings = () => {
    const [summarySettings, setSummarySettings] = useState<SummarySettings | undefined>();
    const [userSummarySettingsDay] = useUserPreference(UserPreference.InsightsSummarySettingsDay);
    const [userSummarySettingsMonth] = useUserPreference(UserPreference.InsightsSummarySettingsMonth);
    const setUserPreference = useSetUserPreference();

    useEffect(() => {
        setSummarySettings({
            day: deserializeSettings(userSummarySettingsDay),
            month: deserializeSettings(userSummarySettingsMonth),
        });
    }, [userSummarySettingsDay, userSummarySettingsMonth]);

    const saveSummarySettings = useCallback(
        (viewMode: SummaryViewMode) => {
            setUserPreference(
                viewMode === "day" ? UserPreference.InsightsSummarySettingsDay : UserPreference.InsightsSummarySettingsMonth,
                serializeSettings(summarySettings[viewMode]),
            );
        },
        [setUserPreference, summarySettings],
    );

    return {
        summarySettings,
        setSummarySettings,
        saveSummarySettings,
    };
};

export const useSummary = () => {
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isResetModalOpen, setIsResetModalOpen] = useState<boolean>(false);
    const [summarySettingsSnapshot, setSummarySettingsSnapshot] = useState<SummarySettings>();
    const { summarySettings, setSummarySettings, saveSummarySettings } = useSummarySettings();
    const { isLoading, hasData } = useHasData();
    const summaryViewMode = useSummaryViewMode();
    const isPersonalizeKpiSummaryEnabled = useFeatureFlag(featureFlags.personalizeKpiSummary);
    const settingsForViewMode = useMemo(() => {
        return summarySettings?.[summaryViewMode];
    }, [summarySettings, summaryViewMode]);

    const toggleEditMode = useCallback(() => {
        if (isEditMode) {
            setSummarySettings(summarySettingsSnapshot);
            setIsEditMode(false);
        } else {
            setSummarySettingsSnapshot(summarySettings);
            setIsEditMode(true);
        }
    }, [isEditMode, summarySettingsSnapshot, summarySettings, setSummarySettings]);

    const toggleResetModal = useCallback(() => {
        setIsResetModalOpen((prevValue) => !prevValue);
    }, []);

    const handleSettingsSave = useCallback(() => {
        saveSummarySettings(summaryViewMode);
        setIsEditMode(false);
    }, [summaryViewMode, saveSummarySettings]);

    const handleSettingsReset = useCallback(() => {
        setSummarySettings({
            ...summarySettings,
            [summaryViewMode]: undefined,
        });
        toggleResetModal();
    }, [summaryViewMode, summarySettings, setSummarySettings, toggleResetModal]);

    const handleGridLayoutChange = useCallback(
        (layouts: Layouts) => setSummarySettings({ ...summarySettings, [summaryViewMode]: { layouts } }),
        [setSummarySettings, summarySettings, summaryViewMode],
    );

    return {
        gridDefaultLayouts: settingsForViewMode?.layouts,
        isGridReady: !!summarySettings,
        isCustomized: !!settingsForViewMode,
        isEditMode: isPersonalizeKpiSummaryEnabled && isEditMode,
        isResetModalOpen,
        toggleResetModal,
        toggleEditMode,
        handleSettingsSave,
        handleSettingsReset,
        handleGridLayoutChange,
        isLoading,
        hasData,
    };
};

export const useSurvicateInSummary = () => {
    const [shouldOpenSurvey, setShouldOpenSurvey] = useState(false);
    const survicate = useSurvicate();

    const handleUserInteraction = useMemo(() => (shouldOpenSurvey ? undefined : () => setShouldOpenSurvey(true)), [setShouldOpenSurvey, shouldOpenSurvey]);

    useEffect(() => {
        if (survicate && shouldOpenSurvey) {
            survicate.invokeEvent(SURVICATE_EVENTS.insights.enterSummary);
        }
    }, [survicate, shouldOpenSurvey]);

    return { handleUserInteraction };
};
