import { useMemo } from "react";

import { useCombinedReportsAndDeviations } from "./useCombinedReportsAndDeviations";
import { useReducedIndicatorValues } from "./useReducedIndicatorValues";
import { useSummaryPeriod } from "./useSummaryPeriod";

export const useHasData = () => {
    const { currentPeriod, previousPeriod } = useSummaryPeriod();
    const { reportsCountData: currentPeriodValues, isLoading: isLoadingCurrent } = useCombinedReportsAndDeviations(currentPeriod.start, currentPeriod.end);
    const { reportsCountData: previousPeriodValues, isLoading: isLoadingPrevious } = useCombinedReportsAndDeviations(previousPeriod.start, previousPeriod.end);
    const { values: currentTours, isLoading: isLoadingCurrentTours } = useReducedIndicatorValues(currentPeriod);
    const { values: previousTours, isLoading: isLoadingPreviousTours } = useReducedIndicatorValues(previousPeriod);

    const hasData = useMemo(() => {
        return !!(
            currentPeriodValues.reportsCount +
            previousPeriodValues.reportsCount +
            currentTours.completedTours +
            currentTours.uncompletedTours +
            previousTours.completedTours +
            previousTours.uncompletedTours
        );
    }, [currentPeriodValues, previousPeriodValues, currentTours, previousTours]);

    return {
        isLoading: isLoadingCurrent || isLoadingPrevious || isLoadingCurrentTours || isLoadingPreviousTours,
        hasData,
    };
};
