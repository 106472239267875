import { differenceInDays, subDays, subMonths } from "date-fns";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { endOfDay, endOfMonth, startOfDay, startOfMonth } from "src/helpers/date";

import { featureFlags } from "../../../../../data/featureFlags";
import { useFeatureFlag } from "../../../../../hooks/featureFlags";
import { SummaryViewMode } from "../../../../../store/insights/InsightsModel";
import InsightsSelectors from "../../../../../store/insights/InsightsSelectors";
import { SummaryPeriod } from "../types";
import { useSummaryViewMode } from "./useSummaryViewMode";

export const useSummaryPeriod = () => {
    const viewMode = useSummaryViewMode();
    const startDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const endDate = useSelector(InsightsSelectors.getSelectedEndDate);
    const isDatepickerEnabled = useFeatureFlag(featureFlags.kpiDatepicker);

    return useMemo(
        () => (isDatepickerEnabled ? calculatePeriodsV2(startDate, endDate) : calculatePeriods(viewMode)),
        [viewMode, startDate, endDate, isDatepickerEnabled],
    );
};

// NOTE: To be removed when KPI Datepicker is fully released
const calculatePeriods = (
    mode: SummaryViewMode,
): {
    currentPeriod: SummaryPeriod;
    previousPeriod: SummaryPeriod;
} => {
    const now = new Date();
    const [subtractPeriod, startOf, endOf] = mode === "month" ? [subMonths, startOfMonth, endOfMonth] : [subDays, startOfDay, endOfDay];
    const current = subtractPeriod(now, 1);
    const previous = subtractPeriod(now, 2);

    return {
        currentPeriod: {
            start: startOf(current),
            end: endOf(current),
        },
        previousPeriod: {
            start: startOf(previous),
            end: endOf(previous),
        },
    };
};

const calculatePeriodsV2 = (
    startDate: Date,
    endDate: Date,
): {
    currentPeriod: SummaryPeriod;
    previousPeriod: SummaryPeriod;
} => {
    const rangeLength = differenceInDays(endDate, startDate) + 1;
    const previousPeriodStart = startOfDay(subDays(startDate, rangeLength));
    const previousPeriodEnd = endOfDay(subDays(startDate, 1));

    return {
        currentPeriod: {
            start: startOfDay(startDate),
            end: endOfDay(endDate),
        },
        previousPeriod: {
            start: startOfDay(previousPeriodStart),
            end: endOfDay(previousPeriodEnd),
        },
    };
};
