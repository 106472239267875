import { Box } from "@secuis/ccp-react-components";

import { useSearch } from "./Search.hooks";
import { SearchStyled } from "./Search.styles";

interface Props {
    value: string;
    suggestions: string[];
    onChange: (value: string) => void;
    submitSearch: (value?: string) => void;
    placeholder?: string;
}

export const Search = ({ value, onChange, submitSearch, suggestions, placeholder }: Props) => {
    const { handleChange, handleClear, handleKeyDown, handleSuggestionClick } = useSearch({
        onChange,
        submitSearch,
    });

    return (
        <Box mb="S" data-testid="settings-csl-search">
            <SearchStyled
                value={value}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onClear={handleClear}
                onSuggestionClick={handleSuggestionClick}
                placeholder={placeholder}
                suggestions={suggestions}
            />
        </Box>
    );
};

Search.displayName = "Search";
