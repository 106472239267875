import { useSelector } from "react-redux";
import type { SummaryViewMode } from "src/store/insights/InsightsModel";

import { featureFlags } from "../../../../../data/featureFlags";
import { useFeatureFlag } from "../../../../../hooks/featureFlags";
import { IState } from "../../../../../Store";

export const useSummaryViewMode = (): SummaryViewMode => {
    const isDatepickerEnabled = useFeatureFlag(featureFlags.kpiDatepicker);
    const viewMode = useSelector(({ insights }: IState) => insights.summaryState.viewMode);

    /**NOTE: To not overcomplicate the logic, if the kpiDatepicker flag is on,
     * we return explicit value from this hook.
     * When we are ready to remove the flag, then go with removing the code related to view mode,
     * especially the part that handles storing user's grid preferences per view mode
     **/
    return isDatepickerEnabled ? "month" : viewMode;
};
