import { BarChart, BarChartDataset, Stack } from "@secuis/ccp-react-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Switcher } from "src/components/shared/Switcher";
import { QaProps } from "src/models";
import styled from "styled-components";

import { Widget } from "../shared/components";
import { WIDGET_VIEW_MODE_OPTIONS } from "../Summary.constants";
import { WidgetViewMode } from "../Summary.types";

type Props = QaProps & {
    isDisabled?: boolean;
};

const STATIC_CHART_DATA: BarChartDataset = {
    id: "data",
    values: ["A", "B", "C", "D", "E"].map((key) => ({
        key,
        value: 2,
    })),
};

const ChartContainer = styled(Stack).attrs({
    flex: 1,
    mt: "M",
})`
    height: 280px;
    flex: 1;
`;

export const SiteReportsWidgetStatic = (props: Props) => {
    const { t } = useTranslation();
    const [viewMode, setViewMode] = useState<WidgetViewMode>("most");
    const handleSetViewMode = (mode: WidgetViewMode) => {
        setViewMode(mode);
    };

    return (
        <Widget
            {...props}
            title={t("insights.summary.siteReportsWidget.title")}
            tooltip={t(`insights.summary.siteReportsWidget.tooltip.${viewMode}`)}
            headerActions={!props.isDisabled && <Switcher options={WIDGET_VIEW_MODE_OPTIONS} onChange={handleSetViewMode} />}
        >
            <ChartContainer>
                <BarChart data={[STATIC_CHART_DATA]} barSize="M" hasTooltip={false} yAxisProps={{ ticks: [0, 10, 20, 30, 40], width: 24 }} />
            </ChartContainer>
        </Widget>
    );
};
