import { BarChart, BarChartDataset, Spacing, Stack } from "@secuis/ccp-react-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Switcher } from "src/components/shared/Switcher";
import styled from "styled-components";

import { CategoriesDropdown, Widget } from "../shared/components";
import { WIDGET_VIEW_MODE_OPTIONS } from "../Summary.constants";
import { WidgetViewMode } from "../Summary.types";

const ChartContainer = styled.div`
    height: 280px;
`;

const STATIC_CHART_DATA: BarChartDataset = {
    id: "data",
    values: ["A", "B", "C", "D", "E"].map((x) => ({
        key: x,
        value: 0,
    })),
    valueLabelFormatter: () => "",
    customBarProps: {
        minPointSize: 10,
    },
};

type Props = {
    isDisabled?: boolean;
};

export const SiteEventsWidgetStatic = ({ isDisabled }: Props) => {
    const { t } = useTranslation();
    const [viewMode, setViewMode] = useState<WidgetViewMode>("most");
    const handleSetViewMode = (mode: WidgetViewMode) => {
        setViewMode(mode);
    };

    return (
        <Widget
            data-testid="site-events-widget"
            title={t("insights.summary.siteEventsWidget.title")}
            tooltip={t(`insights.summary.siteEventsWidget.tooltip.${viewMode}`)}
            headerActions={!isDisabled && <Switcher options={WIDGET_VIEW_MODE_OPTIONS} onChange={handleSetViewMode} />}
        >
            <Stack direction="column" flex={1} gap="XS">
                {!isDisabled && (
                    <Stack mt="XXS" justifyContent="flex-end">
                        <CategoriesDropdown />
                    </Stack>
                )}
                <ChartContainer>
                    <BarChart data={[STATIC_CHART_DATA]} horizontal barSize="S" hasTooltip={false} yAxisProps={{ width: Spacing.S }} />
                </ChartContainer>
            </Stack>
        </Widget>
    );
};
