import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useCategoryInfo, useFetchDeviations } from "src/pages/Insights/shared/hooks";
import { CategoryIncidentCountPerSite } from "src/pages/Insights/shared/types";
import LocationsSelectors from "src/store/locations/LocationsSelectors";

import { useFilteredSites } from "../../../../../../store/insights/FilterHooks";
import { calculateAndSortCategories } from "../../helpers";
import { DataItem, SiteData } from "../../IncidentMatrix.types";
import { useFetchIncidents } from "./useFetchIncidents";
type UseMatrixDataResult = {
    data: DataItem[];
    maxIncidentCount: number;
    isLoading: boolean;
    categories: string[];
};

export const useMatrixData = (categoryKey: string | null): UseMatrixDataResult => {
    const { categories, level } = useCategoryInfo(categoryKey);
    const { siteObjects } = useSelector(LocationsSelectors.getAuthorizedLocations);
    const { siteIds } = useFilteredSites();

    const { incidents, isLoading: isFetchIncidentsLoading } = useFetchIncidents(siteIds, categories, level);
    const { deviations, isLoading: isFetchDeviationsLoading } = useFetchDeviations(siteIds, categories, level);

    const isLoading = isFetchIncidentsLoading || isFetchDeviationsLoading;

    const consolidatedData = useMemo(() => {
        return isLoading ? {} : consolidateIncidentsAndDeviations(incidents, deviations, categories);
    }, [isLoading, incidents, deviations, categories]);

    const matrixData: DataItem[] = useMemo(() => {
        let maxIncidentCountInMatrix = 0;

        const data: DataItem[] = Object.keys(consolidatedData).map((locationId) => {
            const siteData = consolidatedData[locationId];
            const totalIncidentCount = Object.values(siteData).reduce((acc, count) => acc + count, 0);

            const maxForThisLocation = Math.max(...Object.values(siteData));

            if (maxForThisLocation > maxIncidentCountInMatrix) {
                maxIncidentCountInMatrix = maxForThisLocation;
            }

            const categoryData = Object.keys(siteData).reduce((acc, category) => {
                acc[category] = {
                    categoryIncidentCount: siteData[category],
                    totalIncidentCount,
                    siteId: locationId,
                };
                return acc;
            }, {});

            return {
                site: {
                    id: locationId,
                    name: siteObjects.find((site) => site.id === locationId)?.displayName ?? "Unknown",
                    total: totalIncidentCount,
                },
                ...categoryData,
            };
        });

        data.sort((siteA: DataItem, siteB: DataItem) => {
            const totalIncidentCountSiteA = (siteA.site as SiteData).total;
            const totalIncidentCountSiteB = (siteB.site as SiteData).total;
            return totalIncidentCountSiteB - totalIncidentCountSiteA;
        });

        return data.filter((site) => (site.site as SiteData).total > 0);
    }, [consolidatedData, siteObjects]);

    const maxIncidentCountInMatrix = useMemo(() => {
        return matrixData.reduce((max, item) => Math.max(max, (item.site as SiteData).total), 0);
    }, [matrixData]);

    const sortedCategories = useMemo(() => calculateAndSortCategories(matrixData), [matrixData]);

    return {
        categories: sortedCategories,
        data: matrixData,
        maxIncidentCount: maxIncidentCountInMatrix,
        isLoading,
    };
};

const consolidateIncidentsAndDeviations = (
    incidents: CategoryIncidentCountPerSite,
    deviations: CategoryIncidentCountPerSite,
    categories: string[],
): CategoryIncidentCountPerSite => {
    const summary: CategoryIncidentCountPerSite = cloneDeep(incidents);

    for (const locationId in deviations) {
        if (!summary[locationId]) {
            summary[locationId] = {};
        }

        for (const category of categories) {
            summary[locationId][category] = (summary[locationId][category] ?? 0) + (deviations[locationId][category] ?? 0);
        }
    }

    for (const locationId in summary) {
        for (const category of categories) {
            if (!(category in summary[locationId])) {
                summary[locationId][category] = 0;
            }
        }
    }

    return summary;
};
