import { Stack, Text, Theme } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { QaProps } from "src/models";
import styled from "styled-components";

import { Widget } from "../shared/components";
import { ValueTileStatic } from "../shared/components/ValueTileStatic";
import { CHART_LEGEND_CATEGORIES } from "./IncidentDistributionWidget.constants";
import { LegendItem } from "./IncidentDistributionWidget.styles";

const ValueTileStaticStyled = styled(ValueTileStatic)`
    padding: 0;
`;

const Pie = styled.div<{ isDisabled?: boolean }>`
    width: 180px;
    height: 180px;
    background-color: ${({ isDisabled }) => (isDisabled ? Theme.disabled : Theme.secondary)};
    border-radius: 50%;
`;

type Props = QaProps & { isDisabled?: boolean };

export const IncidentDistributionWidgetStatic = ({ isDisabled, ...props }: Props) => {
    const { t } = useTranslation();

    return (
        <Widget
            {...props}
            title={t("insights.summary.incidentDistributionWidget.title")}
            subtitle={<ValueTileStaticStyled label={t("common.event", { count: 0 })} />}
            footer={
                <Stack style={{ alignSelf: "flex-end" }} gap="S" flexWrap="wrap">
                    {CHART_LEGEND_CATEGORIES.map((item) => (
                        <Stack data-testid="incident-distribution-chart-legend-item" gap="XS" key={item.label} alignItems="flex-start">
                            <LegendItem backgroundColor={isDisabled ? Theme.disabled : item.color}>{item.label}</LegendItem>
                            <Text micro>{t(`incident.category.${item.key}`)}</Text>
                        </Stack>
                    ))}
                </Stack>
            }
        >
            <Stack justifyContent="center" flex={1} padding={["XL", "L", "M"]}>
                <Pie isDisabled={isDisabled} />
            </Stack>
        </Widget>
    );
};
