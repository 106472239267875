import { SeverityLevel } from "./ClientSeverityLevel.types";

export const SEVERITY_LEVELS: SeverityLevel[] = [SeverityLevel.Severe, SeverityLevel.High, SeverityLevel.Moderate, SeverityLevel.Low, SeverityLevel.Info];

export const SEVERITY_DESCRIPTIONS = [
    {
        severityLevel: SeverityLevel.Severe,
        description: "severityLevel.description.severe",
    },
    {
        severityLevel: SeverityLevel.High,
        description: "severityLevel.description.high",
    },
    {
        severityLevel: SeverityLevel.Moderate,
        description: "severityLevel.description.moderate",
    },
    {
        severityLevel: SeverityLevel.Low,
        description: "severityLevel.description.low",
    },
    {
        severityLevel: SeverityLevel.Info,
        description: "severityLevel.description.info",
    },
];

export const CATEGORY_ORDER = ["criminal_act_or_suspicious_behavior", "facility", "internal_securitas", "people_and_assets", "health_and_safety"];
