import { Background, Spacing, Stack } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { ExpansionTextPanel } from "src/components/shared/ExpansionTextPanel";
import styled from "styled-components";

import { SEVERITY_DESCRIPTIONS } from "../ClientSeverityLevel.constants";
import { SeverityLevel } from "./SeverityLevel/SeverityLevel";

const StackStyled = styled(Stack).attrs({
    direction: "column",
    padding: "S",
})`
    background-color: ${Background.content};
    gap: 10px;
    overflow: auto;
    border-radius: ${Spacing.XXS}px;
`;

export const SeverityLevelInfo = () => {
    const { t } = useTranslation();
    const renderHeader = (severityLevel: number) => {
        return <SeverityLevel severityLevel={severityLevel} />;
    };

    return (
        <StackStyled>
            {SEVERITY_DESCRIPTIONS.map(({ severityLevel, description }) => (
                <ExpansionTextPanel key={severityLevel} Header={renderHeader(severityLevel)} text={t(description)} visibleCollapsedTextLinesCount={2} />
            ))}
        </StackStyled>
    );
};
