// import { StrictMode } from "react";
import "./styles.css";

import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { createReactRouterV6Options, FaroErrorBoundary, getWebInstrumentations, initializeFaro, ReactIntegration } from "@grafana/faro-react";
import mixpanel from "mixpanel-browser";
import { createRoot } from "react-dom/client";
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from "react-router-dom";
import { getIsProtectasUrl } from "src/helpers/ProtectasHelpers";

import App from "./App";

// initialize MixPanel
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { api_host: "https://mxpproxy.mysecuritas.com" });

// initialize Faro
if (process.env.REACT_APP_FARO_TRACKING_ENABLED === "true") {
    const isProtectas = getIsProtectasUrl();
    const apiUrl = isProtectas ? process.env.REACT_APP_FARO_API_ENDPOINT_PROTECTAS : process.env.REACT_APP_FARO_API_ENDPOINT;

    initializeFaro({
        url: apiUrl,
        apiKey: process.env.REACT_APP_FARO_API_KEY,
        app: {
            name: "MyS One Guarding",
            environment: process.env.REACT_APP_FEATURE_ENV,
        },
        instrumentations: [
            ...getWebInstrumentations(),
            new ReactIntegration({
                router: createReactRouterV6Options({
                    createRoutesFromChildren,
                    matchRoutes,
                    Routes,
                    useLocation,
                    useNavigationType,
                }),
            }),
        ],
    });
}

const onRedirectCallback = (appState: AppState) => {
    window.location.href = appState.targetUrl || appState.returnTo || window.location.pathname;
};

const root = createRoot(document.getElementById("root"));
root.render(
    <>
        {/**
        Strict mode is useful tool for finding bugs during development: https://react.dev/reference/react/StrictMode.
        It fires hooks twice though, which will cause chaos with current http requests handling.
        Using it will be possible after switch from using RSAA + useEffect hooks to RTK.
        Feel free to check it, but please do it only locally.
    */}
        {/*<StrictMode> */}
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            audience={`https://${process.env.REACT_APP_AUTH0_DOMAIN}/userinfo`}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            redirectUri={window.location.origin}
            responseType="id_token"
            scope="openid profile email"
            onRedirectCallback={onRedirectCallback}
        >
            <FaroErrorBoundary>
                <App />
            </FaroErrorBoundary>
        </Auth0Provider>
        {/*</StrictMode> */}
    </>,
);
