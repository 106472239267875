import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";
import { UserPreference } from "src/models/UserModel";
import { useIsUserClientGuardingAdmin } from "src/store/clientSwitcher/ClientSwitcherHooks";
import { useSetUserPreference, useUserPreference } from "src/store/user/UserHooks";

const SETTINGS_APP_GUIDE_NAME = "settingsNavbarLink";

// TODO: Remove and switch to the global app guide, when its redesign is done
export const useNavbarSettingsAppGuide = () => {
    const [showAppGuide, setShowAppGuide] = useState<boolean | null>(null);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const setUserPreference = useSetUserPreference();
    const [appGuideProgress] = useUserPreference(UserPreference.AppGuideProgress) || "";
    const isSettingsAppGuideShown = appGuideProgress.includes(SETTINGS_APP_GUIDE_NAME);
    const isUserClientGuardingAdmin = useIsUserClientGuardingAdmin();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const isClientSeverityLevelEnabled = useFeatureFlag(featureFlags.clientSeverityLevel);
    const isSettingsAppGuideVisible = isClientSeverityLevelEnabled && showAppGuide && !isMobile;

    useEffect(() => {
        if (!isSettingsAppGuideShown && showAppGuide === null) {
            setShowAppGuide(true);
        }
    }, [showAppGuide, isSettingsAppGuideShown]);

    useEffect(() => {
        if (isSettingsAppGuideVisible && !isSettingsAppGuideShown) {
            setUserPreference(UserPreference.AppGuideProgress, appGuideProgress ? `${appGuideProgress},${SETTINGS_APP_GUIDE_NAME}` : SETTINGS_APP_GUIDE_NAME);
        }
    }, [appGuideProgress, isSettingsAppGuideShown, isSettingsAppGuideVisible, setUserPreference]);

    const onSettingsAppGuideClose = () => {
        setShowAppGuide(false);
    };

    const onSettingsAppGuideButtonClick = () => {
        setShowAppGuide(false);
        navigate("/settings");
    };

    return {
        settingsAppGuideTitle: t(`appGuide.settings.severityLevel.title${isUserClientGuardingAdmin ? ".admin" : ""}`),
        settingsAppGuideText: t(`appGuide.settings.severityLevel.text${isUserClientGuardingAdmin ? ".admin" : ""}`),
        settingsAppGuideButtonText: t("appGuide.settings.severityLevel.buttonText"),
        isSettingsAppGuideVisible,
        onSettingsAppGuideClose,
        onSettingsAppGuideButtonClick,
    };
};
