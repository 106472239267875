import { Palette, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const SeverityLevelButtonStyled = styled.button<{ isActive?: boolean }>`
    margin-right: -1px;
    background-color: ${({ isActive }) => (isActive ? Palette.Navy650 : "transparent")};
    border: 1px solid ${({ isActive }) => (isActive ? Palette.Navy100 : `${Palette.Navy300}4d`)};
    transition:
        background-color 0.1s,
        border 0.1s;

    &:not(:last-of-type) {
        border-right-color: ${({ isActive }) => (isActive ? Palette.Navy100 : "transparent")};
    }

    &:first-of-type {
        border-top-left-radius: ${Spacing.XXS}px;
        border-bottom-left-radius: ${Spacing.XXS}px;
    }

    &:last-of-type {
        border-top-right-radius: ${Spacing.XXS}px;
        border-bottom-right-radius: ${Spacing.XXS}px;
    }
`;

export const SeverityLevelButtonContentStyled = styled(Stack).attrs({
    alignItems: "center",
    justifyContent: "center",
})<{ isActive?: boolean }>`
    width: 2.375rem;
    height: 2.375rem;
`;

export const SeverityLevelInfoTextStyled = styled(Text)`
    font-size: 0.875rem;
`;
