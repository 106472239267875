import { Icon, IconType } from "@secuis/ccp-react-components";
import { getSeverityColor } from "src/components/Reports/SharedComponents/Badges";

import { getSeverityIconVariant } from "../ClientSeverityLevel.helpers";
import type { SeverityLevel } from "../ClientSeverityLevel.types";

type Props = {
    severityLevel: SeverityLevel;
};

export const SeverityIcon = ({ severityLevel }: Props) => {
    const iconVariant = getSeverityIconVariant(severityLevel) as IconType;

    return <Icon variant={iconVariant} color={getSeverityColor(severityLevel)} />;
};
