import { EmptyState, Palette, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const EmptyStateStyled = styled(EmptyState)`
    ${Text} {
        color: ${Palette.Gray100};
    }
`;

export const StackStyled = styled(Stack)`
    background-color: ${Palette.Navy750};
    border-radius: ${Spacing.XXS}px;
`;
