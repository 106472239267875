import { Graph, Palette, Spacing, Text } from "@secuis/ccp-react-components";
import { PieChart } from "recharts";
import styled from "styled-components";

import { SummaryValueTile } from "../shared/components/SummaryValueTile";

export const LegendItem = styled(Text).attrs({
    micro: true,
    bold: true,
})<{ backgroundColor: string }>`
    color: ${Palette.Navy750};
    padding: 0 ${Spacing.XXS}px;
    background-color: ${({ backgroundColor }) => `${Graph[backgroundColor] ?? backgroundColor}`};
`;

export const ValueTileStyled = styled(SummaryValueTile)`
    padding: 0;
`;

// hide focus outline when click on pie
export const StyledPieChart = styled(PieChart)`
    .recharts-sector:focus,
    .recharts-layer:focus,
    .recharts-surface:focus {
        outline: none;
    }

    .recharts-layer path {
        transition: opacity 0.1s;
        &:hover {
            opacity: 0.9;
            cursor: pointer;
        }
    }
`;
