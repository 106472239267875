import { Image, Text, View } from "@react-pdf/renderer";
import { Spacing } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAutoTranslatedText } from "src/helpers/autoTranslations";
import { formatDateTime } from "src/helpers/date";
import { IImageData, ImageSizePaths, IReportImage } from "src/models/ReportImagesModels";

import { useAutoTranslationWhenNotTranslated } from "../../../helpers/autoTranslations/hooks";
import ReportsSelectors from "../../../store/reports/ReportsSelectors";
import { styles } from "../pdfStyles";

export type TextSummaryFieldPdfType = {
    fieldName: string;
    fieldValue: string;
    isRequired?: boolean;
    shouldAutoTranslate?: boolean;
    image?: IImageData;
};

export const TextSummaryFieldPdf = ({ fieldName, fieldValue, isRequired = true, image, shouldAutoTranslate = true }: TextSummaryFieldPdfType) => {
    const { t } = useTranslation();
    const { translatedText: textValue } = useAutoTranslationWhenNotTranslated(fieldValue, shouldAutoTranslate);
    const { translatedText: textLabel } = useAutoTranslationWhenNotTranslated(fieldName, shouldAutoTranslate);

    const { byImagePath } = useSelector(ReportsSelectors.getReportImages);
    const reportImage = image && byImagePath[image[ImageSizePaths.Small]];

    if (!isRequired && !(fieldValue || image)) return null;

    return (
        <View style={{ ...styles.inlineFullWidth, fontSize: 11 }}>
            <Text style={styles.summaryFieldName}>{textLabel}</Text>
            <View style={{ ...styles.flexRow, flex: 1, alignItems: "center", justifyContent: "flex-end" }}>
                {!!reportImage && <Image source={reportImage.image} style={styles.propertyImage} />}
                {(textValue || !reportImage) && <Text style={{ marginLeft: reportImage ? Spacing.XS : 0 }}>{textValue || t("common.noDataFound")}</Text>}
            </View>
        </View>
    );
};

type ImageSummaryFieldPdfType = {
    fieldName: string;
    fieldValue: IReportImage;
    isRequired?: boolean;
};

export const ImageSummaryFieldPdf = ({ fieldName, fieldValue, isRequired = true }: ImageSummaryFieldPdfType) => {
    const { t, i18n } = useTranslation();
    if (!isRequired && !fieldValue?.image) return null;
    const textFieldName = fieldName && i18n.exists(fieldName) ? t(fieldName) : fieldName;

    return (
        <View style={{ ...styles.inlineFullWidth, fontSize: 11 }}>
            <Text style={styles.summaryFieldName}>{textFieldName}</Text>
            {!fieldValue?.image ? (
                <Text style={{ flex: 1, textAlign: "right" }}>{t("common.noDataFound")}</Text>
            ) : (
                <Image source={fieldValue.image} style={styles.propertyImage} />
            )}
        </View>
    );
};

export const DateSummaryFieldPdf = ({ fieldName, fieldValue, isRequired = true }) => {
    const { t } = useTranslation();

    if (!isRequired && !fieldValue) return null;

    return (
        <View style={{ ...styles.inlineFullWidth, fontSize: 11 }} wrap={false}>
            <Text style={styles.summaryFieldName}>{t(fieldName)}</Text>
            <Text style={styles.summaryFieldValue}>{fieldValue ? formatDateTime(fieldValue) : t("common.noDataFound")}</Text>
        </View>
    );
};

export const DateRangeSummaryFieldPdf = ({ fieldName, fieldValue, fieldValue2, isRequired = true }) => {
    const { t } = useTranslation();

    const dateValue = (date: Date | string) => {
        if (!date) {
            return t("common.noDataFound");
        }
        return typeof date === "string" ? date : formatDateTime(date);
    };

    if (!isRequired && !fieldValue && !fieldValue2) return false;

    return (
        <View style={styles.inlineFullWidth} wrap={false}>
            <Text style={{ flex: 1, ...styles.boldText }}>{t(fieldName)}</Text>
            <View style={{ ...styles.inlineFullWidth, width: "65%" }}>
                <Text style={styles.fromToText}>{t("common.from")}</Text>
                <Text style={{ marginRight: 8, ...styles.fromToDateValue }}>{dateValue(fieldValue)}</Text>
                <Text style={styles.fromToText}>{t("common.to")}</Text>
                <Text style={styles.fromToDateValue}>{dateValue(fieldValue2)}</Text>
            </View>
        </View>
    );
};

export type TextAreaSummaryFieldPdfType = {
    fieldName: string;
    fieldValue: string;
    isRequired?: boolean;
    shouldAutoTranslate?: boolean;
};

export const TextAreaSummaryFieldPdf = ({ fieldName, fieldValue, isRequired = true, shouldAutoTranslate = false }: TextAreaSummaryFieldPdfType) => {
    const { t } = useTranslation();
    const { translatedText: autoTranslatedFieldValue } = useAutoTranslatedText(fieldValue?.toString() ?? "", shouldAutoTranslate);
    const { translatedText: labelText } = useAutoTranslationWhenNotTranslated(fieldName, shouldAutoTranslate);

    if (!isRequired && !fieldValue) return null;

    return (
        <View style={{ width: "100%", fontSize: 11 }} wrap={false}>
            <Text style={styles.boldText}>{labelText}</Text>
            <Text>{autoTranslatedFieldValue || t("common.noDataFound")}</Text>
        </View>
    );
};
