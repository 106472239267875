import { UIEvent, useCallback, useMemo, useRef, useState } from "react";
import { VirtuosoHandle } from "react-virtuoso";
import { ScrollTopButton } from "src/components/shared/ScrollTopButton/ScrollTopButton";

import { hasReportBeenViewedByUser } from "../../../helpers/ReportHelper";
import { ReportsListItemType } from "../../../models/ReportModel";
import { useUserInfo } from "../../../store/user/UserHooks";
import { VirtualListContext } from "../Reports.types";
import { useScrollToTop } from "./ReportsList.hooks";
import { RelativeWrapper, VirtuosoStyled } from "./ReportsList.styles";
import { ReportsListItem } from "./ReportsListItem";
import { VirtualListContainer } from "./VirtualListContainer";
import { VirtualListEmptyPlaceholder } from "./VirtualListEmptyPlaceholder";
import { VirtualListFooter } from "./VirtualListFooter";
import { VirtualListHeader } from "./VirtualListHeader";

type Props = {
    parentRef: React.RefObject<HTMLDivElement>;
    reports: ReportsListItemType[];
    activeReportId: string;
    onReportClick: (id: string, locationId: string, type: string) => void;
    loadMore: () => void;
    context: VirtualListContext;
};

const EMPTY_ARRAY = [];

export const ReportsList = ({ parentRef, reports, onReportClick, activeReportId, loadMore, context }: Props) => {
    const [isScrollBlocked, setIsScrollBlocked] = useState<boolean>(false);
    const [isScrollTopDisplayed, setIsScrollTopDisplayed] = useState<boolean>(false);
    const { info: user } = useUserInfo();
    const { totalCount, isMobile, isFilterToolbarVisible } = context;
    const virtuosoRef = useRef<VirtuosoHandle>(null);

    const scrollToTop = useScrollToTop(parentRef, virtuosoRef);

    const isMobileFilterActive = useMemo(() => {
        return isMobile && isFilterToolbarVisible;
    }, [isMobile, isFilterToolbarVisible]);

    const onScroll = useCallback(
        (event: UIEvent<HTMLDivElement>) => {
            const containerElement = event?.target as HTMLDivElement;

            if (!containerElement) {
                return;
            }

            const { scrollTop, clientHeight } = containerElement;
            const controlPosition = clientHeight / 2;

            if (scrollTop >= controlPosition && !isScrollTopDisplayed) {
                setIsScrollTopDisplayed(true);
            } else if (scrollTop < controlPosition && isScrollTopDisplayed) {
                setIsScrollTopDisplayed(false);
            }
        },
        [isScrollTopDisplayed],
    );

    return (
        <>
            {isScrollTopDisplayed && (
                <RelativeWrapper>
                    <ScrollTopButton data-testid="arrow-up" onClick={scrollToTop} />
                </RelativeWrapper>
            )}
            <VirtuosoStyled
                data-testid="report-list-and-search-container"
                onScroll={onScroll}
                ref={virtuosoRef}
                totalCount={totalCount}
                context={context}
                data={isMobileFilterActive ? EMPTY_ARRAY : reports}
                style={{ position: isMobileFilterActive ? "static" : "relative" }}
                tabIndex={-1}
                endReached={() => totalCount > reports.length && loadMore()}
                isScrollBlocked={isScrollBlocked}
                itemContent={(index, report) => (
                    <ReportsListItem
                        key={report.id}
                        report={report}
                        isActive={report.id === activeReportId}
                        unread={!hasReportBeenViewedByUser(report, user)}
                        onClick={onReportClick}
                        onSwipeStart={() => setIsScrollBlocked(true)}
                        onSwipeEnd={() => setIsScrollBlocked(false)}
                    />
                )}
                components={{
                    Header: VirtualListHeader,
                    List: VirtualListContainer,
                    Footer: VirtualListFooter,
                    EmptyPlaceholder: VirtualListEmptyPlaceholder,
                }}
            />
        </>
    );
};
