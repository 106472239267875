import { useTranslation } from "react-i18next";

import { useReducedIndicatorValues, useSummaryPeriod } from "../shared/hooks";

export const usePerformanceIndicatorsWidget = () => {
    const { t } = useTranslation();
    const { currentPeriod, previousPeriod } = useSummaryPeriod();
    const { values: currentValues, isLoading: isLoadingCurrent } = useReducedIndicatorValues(currentPeriod);
    const { values: previousValues, isLoading: isLoadingPrevious } = useReducedIndicatorValues(previousPeriod);

    const indicators = [
        {
            title: t("insights.summary.performanceIndicators.completedTours"),
            value: currentValues.completedTours,
            prevValue: previousValues.completedTours,
        },
        {
            title: t("insights.summary.performanceIndicators.uncompletedTours"),
            value: currentValues.uncompletedTours,
            prevValue: previousValues.uncompletedTours,
        },
    ];

    return { indicators, isLoading: isLoadingCurrent || isLoadingPrevious };
};
