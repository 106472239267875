import { Breakpoints, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { scheduleSlice } from "src/store/schedule/scheduleSlice";
import { getSelectedDate } from "src/store/schedule/selectors";

import { DateInput } from "../shared/filters";

type Props = {
    onCalendarOpen?: () => void;
};

export const ScheduleDateFilter = ({ onCalendarOpen }: Props) => {
    const { t } = useTranslation();
    const date = useSelector(getSelectedDate);
    const dispatch = useDispatch();
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    const setDate = useCallback(
        (seletedDate: Date) => {
            dispatch(scheduleSlice.actions.changeDate(seletedDate));
        },
        [dispatch],
    );

    const handleDateChange = useCallback((startDate: Date) => {
        setDate(startDate);
    }, []);

    return (
        <Stack marginTop={["0", "0", "XS"]} flex={isMobile ? 1 : null}>
            <DateInput
                placeholder={t("datepicker.placeholder")}
                onDateChange={handleDateChange}
                selectedStartDate={date}
                selectedEndDate={date}
                mode="singleMonth"
                isDaySelection
                onCalendarOpen={onCalendarOpen}
                allowSelectFuture
            />
        </Stack>
    );
};
