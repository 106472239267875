function escapeRegex(value: string) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#]/g, "\\$&");
}

export const highlightText = (value: string, highlight: string) => {
    if (!highlight) {
        return [{ chunk: value, highlighted: false }];
    }

    const escapedHighlight = escapeRegex(highlight.trim());

    if (escapedHighlight.length === 0) {
        return [{ chunk: value, highlighted: false }];
    }

    const regex = new RegExp(`(${escapedHighlight})`, "gi");
    return value
        .split(regex)
        .map((part) => ({
            chunk: part,
            highlighted: regex.test(part),
        }))
        .filter(({ chunk }) => chunk);
};
