import { BarChart, BarChartDataset, Spacing, Stack } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { getWeekDayLabel } from "../../../../helpers/date/date";
import { Widget } from "../shared/components";
import { WEEKDAYS_ORDER } from "./DeviantDayWidget.constants";

const STATIC_DATA: BarChartDataset = {
    id: "data",
    values: WEEKDAYS_ORDER.map((day) => ({
        key: getWeekDayLabel(day, true),
        value: 4,
    })),
};

const ChartContainer = styled(Stack).attrs({
    flex: 1,
    mt: "M",
})`
    height: 280px;
    flex: 1;
`;

export const DeviantDayWidgetStatic = () => {
    const { t } = useTranslation();

    return (
        <Widget title={t("insights.summary.deviantDay")} tooltip={t("insights.summary.deviantDayWidget.tooltip")}>
            <ChartContainer>
                <BarChart data={[STATIC_DATA]} hasTooltip={false} yAxisProps={{ ticks: [0, 50, 100], width: Spacing.L }} />
            </ChartContainer>
        </Widget>
    );
};
