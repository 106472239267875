import { Spacing } from "@secuis/ccp-react-components";
import { UIEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

import { ScrollTopButton } from "../shared/ScrollTopButton/ScrollTopButton";
import { RelativeWrapper, ScrollableContentStyled } from "./Schedule.styles";

export const ScrollableContent = ({ children }) => {
    const scrollableWrapperRef = useRef<HTMLDivElement>();
    const [height, setHeight] = useState<number>();
    const [isScrollTopDisplayed, setIsScrollTopDisplayed] = useState<boolean>(false);

    const updateHeight = useCallback(() => {
        const topPosition = scrollableWrapperRef.current.getBoundingClientRect().top;
        const bodyHeight = document.body.clientHeight;
        setHeight(bodyHeight - topPosition - Spacing.S);
    }, [scrollableWrapperRef]);

    useLayoutEffect(() => {
        updateHeight();
    }, [updateHeight]);

    useEffect(() => {
        window.addEventListener("resize", updateHeight);

        return () => {
            window.removeEventListener("resize", updateHeight);
        };
    }, [updateHeight]);

    const onScroll = (event: UIEvent<HTMLDivElement>) => {
        const containerElement = event?.target as HTMLDivElement;

        if (!containerElement) {
            return;
        }
        const { scrollTop, clientHeight } = containerElement;
        const controlPosition = clientHeight / 2;

        if (scrollTop >= controlPosition) {
            setIsScrollTopDisplayed(true);
        } else {
            setIsScrollTopDisplayed(false);
        }
    };

    const handleScrollTop = () => {
        scrollableWrapperRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <RelativeWrapper>
            <ScrollableContentStyled onScroll={onScroll} height={height} ref={scrollableWrapperRef}>
                {children}
            </ScrollableContentStyled>
            {isScrollTopDisplayed && <ScrollTopButton data-testid="arrow-up" onClick={handleScrollTop} />}
        </RelativeWrapper>
    );
};
