import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DbsContext } from "../../components/DatabaseProvider";
import { featureFlags } from "../../data/featureFlags";
import { useFeatureFlag } from "../../hooks/featureFlags";
import { useInsightsApi } from "../../hooks/InsightsApiHooks";
import { createInsightsDbQuery } from "../../mocks/helpers/createInsightsDbQuery";
import { Databases, IDbContext } from "../../models/DbModel";
import { insertAllHighlightsCategories } from "../../sql/scripts/insights/highlightsQueries";
import { initReportsDbScript } from "../../sql/scripts/reportsDbInit";
import { useAuthorizedSiteObjectIds } from "../locations/LocationsHooks";
import { RequestStatus } from "../RequestStatus";
import InsightsActions from "./InsightsActions";
import InsightsSelectors from "./InsightsSelectors";

export const useDatabase = (id: string): IDbContext => {
    const context = useContext(DbsContext);
    return context[id];
};

const useInitializeReportsDb = (): void => {
    const dispatch = useDispatch();
    const insightsApi = useInsightsApi();
    const { locationIds } = useAuthorizedSiteObjectIds();
    const { loadStatus } = useSelector(InsightsSelectors.getDatabasesState(Databases.Reports));
    const isDemoClientFeatureEnabled = useFeatureFlag(featureFlags.inMemoryDemoClient);

    const { open, execute } = useDatabase(Databases.Reports);

    useEffect(() => {
        const loadReportsDb = async () => {
            try {
                dispatch(InsightsActions.loadDb(Databases.Reports));
                const db = await insightsApi.getReportsDb(locationIds);

                if (isDemoClientFeatureEnabled) {
                    const textDecoder = new TextDecoder("utf-8");
                    const reports = JSON.parse(textDecoder.decode(db));
                    const sqlQuery = createInsightsDbQuery(reports);

                    await execute(sqlQuery);
                } else {
                    await open(db);
                }

                if (db) {
                    await execute(initReportsDbScript);
                    await execute(insertAllHighlightsCategories());
                }

                dispatch(InsightsActions.loadDbSuccess(Databases.Reports));
            } catch (error) {
                dispatch(InsightsActions.loadDbFailure(Databases.Reports, error));
            }
        };
        if (loadStatus === RequestStatus.undefined) {
            loadReportsDb();
        }
    }, [dispatch, insightsApi, open, execute, locationIds, loadStatus, isDemoClientFeatureEnabled]);
};

export const useInitializeInsightsDb = (): void => {
    useInitializeReportsDb();
};
