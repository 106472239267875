import { IconType } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";

import { IImageData } from "../../../../models/ReportImagesModels";
import ReportImagesCarousel from "../../ReportImagesCarousel";
import { TextSummaryField } from "../TextSummaryField";

type Props = {
    question: string;
    answer: string;
    isTrueException?: boolean;
    shouldAutoTranslate?: boolean;
    image?: IImageData;
    sharedReportId?: string;
};

export const ExceptionQuestionField = ({ question, answer, image, sharedReportId, isTrueException = false, shouldAutoTranslate = true }: Props) => {
    const { t } = useTranslation();
    const isTrueDeviationsEnabled = useFeatureFlag(featureFlags.trueDeviations);

    if (!question) return <></>;

    const deviationTag =
        isTrueDeviationsEnabled && isTrueException
            ? {
                  text: t("reportlist.deviations"),
                  icon: "Deviations" as IconType,
              }
            : undefined;

    return (
        <TextSummaryField
            key={question}
            fieldName={question}
            fieldValue={answer}
            tag={deviationTag}
            allowEmptyValue={!!image}
            shouldAutoTranslate={shouldAutoTranslate}
            desktopDirection="row"
            mobileDirection="column"
            mobileValueAlignment="end"
        >
            {image && <ReportImagesCarousel imagesData={[image]} sharingId={sharedReportId} flex={1} />}
        </TextSummaryField>
    );
};
