import _debounce from "lodash/debounce";
import { MutableRefObject, useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReportsListItemType } from "src/models/ReportModel";

import { useInfiniteScroll } from "../../hooks/CommonHooks";
import { SearchReportsRequestParams } from "../../models/ReportFilterModels";
import { IImageData, ImageSizePaths } from "../../models/ReportImagesModels";
import { ISiteObject } from "../../models/SiteObjectModel";
import AccessSelectors from "../access/AccessSelectors";
import { useFilteredSites, useFiltersSelection, useReportTypeItems } from "../filter/FilterHooks";
import FilterSelectors from "../filter/FilterSelectors";
import LocationsSelectors from "../locations/LocationsSelectors";
import CommentActions from "../reportComments/CommentActions";
import { RequestStatus } from "../RequestStatus";
import UserSelectors from "../user/UserSelectors";
import { makeReportSearchKey } from "./keys";
import {
    useLazyFetchReportsFromSearchQuery,
    useLazyGetImageByPathQuery,
    useLazyGetRelatedReportQuery,
    useLazyGetReportQuery,
    useLazyGetSharedReportQuery,
    useLazyGetSharedTourSessionQuery,
    useLazyGetTourSessionQuery,
} from "./reportsApi";
import ReportsSelectors from "./ReportsSelectors";
import { reportsSlice } from "./reportsSlice";

export const useSingleReport = (id: string, locationId: string, isTour: boolean) => {
    const dispatch = useDispatch();
    const currentReport = useSelector(ReportsSelectors.getCurrentReport);
    const { info: user } = useSelector(UserSelectors.getUserInfo);
    const siteObject = useSelector(LocationsSelectors.getAuthorizedLocationById(locationId));
    const [getReport] = useLazyGetReportQuery();
    const [getTourSession] = useLazyGetTourSessionQuery();
    const [getRelatedReport] = useLazyGetRelatedReportQuery();

    const loadSingleReport = useCallback(() => {
        dispatch(CommentActions.setEntryDefaultState());
        if (isTour) {
            getTourSession({ reportId: id, siteObjectId: locationId });
        } else {
            getReport({ reportId: id, siteObjectId: locationId });
        }
    }, [id, locationId, isTour, dispatch]);

    const report = useMemo(() => {
        if (id && currentReport?.id === id) {
            return currentReport?.report;
        }
        return null;
    }, [id, currentReport]);

    const loading = useMemo(() => {
        return !currentReport.report || currentReport?.status === RequestStatus.loading || currentReport.id !== id;
    }, [currentReport, id]);

    const error = useMemo(() => {
        return currentReport?.id === id && currentReport?.error;
    }, [id, currentReport]);

    useEffect(() => {
        const canFetch = user && id && (id !== currentReport?.id || currentReport.status === RequestStatus.undefined);
        if (canFetch) {
            loadSingleReport();
        }
    }, [id, currentReport, user, loadSingleReport]);

    useEffect(() => {
        report?.exceptionReportIds?.map((exceptionId) => {
            getRelatedReport({ reportId: exceptionId, siteObjectId: report.locationId });
        });
    }, [report?.exceptionReportIds, report?.id, report?.locationId]);

    return { report, siteObject, loading, error };
};
export const useSharedReport = (id: string, isTour = false) => {
    const currentReport = useSelector(ReportsSelectors.getCurrentReport);
    const { info: user } = useSelector(UserSelectors.getUserInfo);
    const [getSharedReport] = useLazyGetSharedReportQuery();
    const [getSharedTourSession] = useLazyGetSharedTourSessionQuery();

    const report = useMemo(() => {
        if (!id) {
            return null;
        }
        if (currentReport?.report?.report) {
            return currentReport.report.report;
        }
        if (currentReport?.report?.tourSession) {
            return {
                ...currentReport.report.tourSession,
                exceptions: currentReport?.report?.exceptions,
                pdfPieChart: currentReport?.report?.pdfPieChart,
            };
        }
        return null;
    }, [id, currentReport]);

    const siteObject: ISiteObject = useMemo(() => {
        if (!currentReport?.report?.site) {
            return null;
        }
        return { ...currentReport.report.site, displayName: currentReport.report.site.name };
    }, [currentReport]);

    const loading = useMemo(() => {
        return !currentReport.report || currentReport?.status === RequestStatus.loading;
    }, [currentReport]);

    const error = useMemo(() => {
        return currentReport?.error;
    }, [currentReport]);

    const expirationTimestamp = useMemo(() => {
        return id ? currentReport?.report?.expirationTimestamp : null;
    }, [id, currentReport]);

    useEffect(() => {
        const canFetch =
            currentReport.status === RequestStatus.undefined ||
            (currentReport.status !== RequestStatus.loading && currentReport.status !== RequestStatus.error);

        if (!canFetch) {
            return;
        }

        if (id && id !== currentReport?.report?.shareId) {
            if (isTour) {
                getSharedTourSession({ reportId: id });
            } else {
                getSharedReport({ reportId: id });
            }
        }
    }, [currentReport, id, isTour, user]);

    return { report, siteObject, loading, error, expirationTimestamp };
};

export const useReportImages = (imagesData: IImageData[], size: ImageSizePaths, sharingId?: string, fetchData = true) => {
    const dispatch = useDispatch();
    const [getImageByPath] = useLazyGetImageByPathQuery();
    const { byImagePath, loadingMultiple } = useSelector(ReportsSelectors.getReportImages);
    const imagePaths: string[] = useMemo(() => {
        if (imagesData) {
            return imagesData.map((imgData) => imgData[size]);
        }
        return [];
    }, [imagesData, size]);

    const reportImages = useMemo(() => {
        if (imagePaths.length && Object.keys(byImagePath).length && imagePaths.every((path) => byImagePath[path])) {
            return imagePaths.map((path) => byImagePath[path]);
        }
        return [];
    }, [byImagePath, imagePaths]);

    useEffect(() => {
        const getImages = async () => {
            dispatch(reportsSlice.actions.setLoadingMultiple(true));
            for (const imageData of imagesData) {
                await getImageByPath({ imageData, size, sharingId });
            }
            dispatch(reportsSlice.actions.setLoadingMultiple(false));
        };
        if (fetchData && !loadingMultiple && imagePaths.length && !reportImages.length) {
            getImages();
        }
    }, [dispatch, fetchData, imagesData, imagePaths, loadingMultiple, reportImages, size, sharingId, getImageByPath]);

    return { reportImages, loadingMultiple };
};

export const useReportListFiltersCount = (): number => {
    const selectedCategoryOneIds = useSelector(FilterSelectors.getSelectedCategoryOneIds);
    const selectedCategoryTwoIds = useSelector(FilterSelectors.getSelectedCategoryTwoIds);
    const selectedCategoryThreeIds = useSelector(FilterSelectors.getSelectedCategoryThreeIds);
    const selectedSeverityLevelIds = useSelector(FilterSelectors.getSelectedSeverityLevelIds);
    const selectedReportTypes = useSelector(FilterSelectors.getSelectedReportTypes);
    const selectedSiteLocations = useSelector(FilterSelectors.getSelectedSiteLocations);
    const selectedRegions = useSelector(FilterSelectors.getSelectedRegions);
    const showOnlyUnread = useSelector(FilterSelectors.getShowOnlyUnreadSelector);

    const { selectedFiltersCount } = useFiltersSelection(
        selectedSeverityLevelIds,
        selectedReportTypes,
        [...selectedCategoryOneIds, ...selectedCategoryTwoIds, ...selectedCategoryThreeIds],
        selectedSiteLocations,
        selectedRegions,
        showOnlyUnread,
    );

    return selectedFiltersCount;
};

export const useReportSearch = (): {
    reports: ReportsListItemType[];
    pageNumber: number;
    totalCount: number;
    pageSize: number;
    status: RequestStatus;
    refreshing: boolean;
    loadMoreReports: () => void;
} => {
    const reportSearchByKey = useSelector(ReportsSelectors.getReportsFromSearch);
    const userId = useSelector(AccessSelectors.getUserId);
    const [fetchReportsFromSearch] = useLazyFetchReportsFromSearchQuery();
    const { siteIds: selectedSitesIds } = useFilteredSites();
    const selectedCategoryOneIds = useSelector(FilterSelectors.getSelectedCategoryOneIds);
    const selectedCategoryTwoIds = useSelector(FilterSelectors.getSelectedCategoryTwoIds);
    const selectedCategoryThreeIds = useSelector(FilterSelectors.getSelectedCategoryThreeIds);
    const selectedSeverityLevelIds = useSelector(FilterSelectors.getSelectedSeverityLevelIds);
    const selectedReportTypes = useSelector(FilterSelectors.getSelectedReportTypes);
    const selectedSiteLocations = useSelector(FilterSelectors.getSelectedSiteLocations);
    const fromDateTime = useSelector(FilterSelectors.getSelectedStartDate);
    const toDateTime = useSelector(FilterSelectors.getSelectedEndDate);
    const showOnlyUnread = useSelector(FilterSelectors.getShowOnlyUnreadSelector);
    const locations = useSelector(LocationsSelectors.getAuthorizedLocations);
    const { getReportTypeFilterSelection } = useReportTypeItems();

    const debounceSearchRef = useRef(
        _debounce(
            (searchKey, body, pageSize, pageNumber) => {
                fetchReportsFromSearch({
                    key: searchKey,
                    body,
                    pageSize,
                    pageNumber,
                    timestampRequested: Date.now(),
                });
            },
            50,
            { leading: false },
        ),
    );

    const searchParams = useMemo(() => {
        return {
            locationIds: selectedSitesIds,
            reportCategories1: selectedCategoryOneIds,
            reportCategories2: selectedCategoryTwoIds,
            reportCategories3: selectedCategoryThreeIds,
            reportTypes: selectedReportTypes,
            siteLocations: selectedSiteLocations,
            severityLevels: selectedSeverityLevelIds,
            fromDateTime,
            toDateTime,
            showOnlyUnread,
        };
    }, [
        selectedSitesIds,
        selectedCategoryOneIds,
        selectedCategoryTwoIds,
        selectedCategoryThreeIds,
        selectedReportTypes,
        selectedSiteLocations,
        selectedSeverityLevelIds,
        fromDateTime,
        toDateTime,
        showOnlyUnread,
    ]);
    const searchKey = useMemo(() => {
        return makeReportSearchKey({ ...searchParams });
    }, [searchParams]);
    const siteLocationIds = searchParams.locationIds.filter((locationId) => locations.siteObjects.some((s) => s.id === locationId));

    const status = useMemo(() => {
        return reportSearchByKey[searchKey]?.status;
    }, [searchKey, reportSearchByKey]);

    const refreshing = useMemo(() => {
        return reportSearchByKey[searchKey]?.refreshing ?? true;
    }, [searchKey, reportSearchByKey]);

    const reportSearchResult = useMemo(() => {
        return reportSearchByKey[searchKey];
    }, [searchKey, reportSearchByKey]);

    const actualPageNumber = useMemo(() => {
        return Math.max(reportSearchByKey[searchKey]?.page, 0);
    }, [searchKey, reportSearchByKey]);

    const loadReports = useCallback(
        (debounce = false) => {
            // NOTE: We run request even when siteLocationIds is empty due to
            // how reports requests is organized at the moment.
            // There is no smooth way not to run request conditionally, hence we run it nevertheless
            // and handle empty data like always.
            // GLOB-4591
            const reportTypeFilter = getReportTypeFilterSelection(searchParams.reportTypes);
            const body: SearchReportsRequestParams = {
                locationIds: siteLocationIds,
                userId,
                reportCategories1: searchParams.reportCategories1,
                reportCategories2: searchParams.reportCategories2,
                reportCategories3: searchParams.reportCategories3,
                reportTypes: reportTypeFilter.selectedReportTypes,
                excludedReportTypes: reportTypeFilter.excludedReportTypes,
                siteLocations: searchParams.siteLocations,
                severityLevels: searchParams.severityLevels,
                from: searchParams.fromDateTime,
                to: searchParams.toDateTime,
                isReadReport: searchParams.showOnlyUnread ? false : null,
            };
            const pageSize = 20;
            const pageNumber = actualPageNumber + 1 || 0;
            if (debounce) {
                debounceSearchRef.current(searchKey, body, pageSize, pageNumber);
            } else {
                fetchReportsFromSearch({
                    key: searchKey,
                    body,
                    pageSize,
                    pageNumber,
                    timestampRequested: Date.now(),
                });
            }
        },
        [getReportTypeFilterSelection, userId, actualPageNumber, searchKey, searchParams, siteLocationIds, fetchReportsFromSearch],
    );

    const loadMoreReports = useCallback(() => {
        if (status !== RequestStatus.loading) {
            loadReports();
        }
    }, [loadReports, status]);

    useEffect(() => {
        if (!reportSearchByKey[searchKey]) {
            loadReports(true);
        }
    }, [loadReports, searchKey, reportSearchByKey, siteLocationIds]);

    return {
        reports: reportSearchResult?.reports ?? [],
        pageNumber: reportSearchResult?.page ?? 0,
        totalCount: reportSearchResult?.totalCount ?? 0,
        pageSize: reportSearchResult?.size ?? 0,
        status,
        refreshing,
        loadMoreReports,
    };
};

export const useInifiteScrollReportSearch = (
    scrollRef: MutableRefObject<HTMLDivElement>,
): {
    reports: any[];
    totalCount: number;
    refreshing: boolean;
} => {
    const { reports, refreshing, totalCount, loadMoreReports } = useReportSearch();

    useInfiniteScroll(scrollRef, () => {
        if (!refreshing && reports.length < totalCount) {
            loadMoreReports();
        }
    });

    return { reports, refreshing, totalCount };
};

export const useRelatedReportsReady = (report) => {
    const currentReport = useSelector(ReportsSelectors.getCurrentReport);
    return report?.exceptionReportIds?.length > 0
        ? currentReport?.relatedReportsStatus?.length === report?.exceptionReportIds?.length &&
              currentReport?.relatedReportsStatus?.every((x) => x.status === RequestStatus.success)
        : true;
};
