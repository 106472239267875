import { Spacing, Stack } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { HorizontalLine } from "src/components/shared/HorizontalLine";

import { Widget } from "../shared/components";
import { ValueTileStatic } from "../shared/components/ValueTileStatic";
import { SEPARATOR_COLOR } from "../Summary.constants";

export const ReportsCountWidgetStatic = () => {
    const { t } = useTranslation();

    return (
        <Widget>
            <Stack direction="column" flex={1}>
                <ValueTileStatic label={t("common.reports", { count: 0 })} title={t("insights.summary.reportsCountWidget.reportsCount")} />
                <HorizontalLine color={SEPARATOR_COLOR} marginTop={0} marginBottom={Spacing.M} />
                <ValueTileStatic label={t("common.sites", { count: 0 })} title={t("insights.summary.reportsCountWidget.sitesWithReports")} />
                <HorizontalLine color={SEPARATOR_COLOR} marginTop={0} marginBottom={Spacing.M} />
                <ValueTileStatic label={t("common.sites", { count: 0 })} title={t("insights.summary.reportsCountWidget.sitesWithoutReports")} />
            </Stack>
        </Widget>
    );
};
