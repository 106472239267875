import { Stack } from "@secuis/ccp-react-components";

import { CollapsibleSections } from "./CollapsibleSection";

export interface ListItem {
    text: string;
    subitems?: ListItem[];
}

export interface SectionConfig {
    title: string;
    listItems: ListItem[];
}

const sectionConfig: SectionConfig = {
    title: "settings.clientSeverityLevel.importance.title",
    listItems: [
        { text: "settings.clientSeverityLevel.importance.tip1" },
        { text: "settings.clientSeverityLevel.importance.tip2" },
        {
            text: "settings.clientSeverityLevel.importance.tip3",
            subitems: [{ text: "settings.clientSeverityLevel.importance.subTip1" }, { text: "settings.clientSeverityLevel.importance.subTip2" }],
        },
    ],
};

const sectionConfig2: SectionConfig = {
    title: "settings.clientSeverityLevel.instructions.title",
    listItems: [
        { text: "settings.clientSeverityLevel.instructions.step1" },
        { text: "settings.clientSeverityLevel.instructions.step2" },
        { text: "settings.clientSeverityLevel.instructions.step3" },
        { text: "settings.clientSeverityLevel.instructions.step4" },
        { text: "settings.clientSeverityLevel.instructions.step5" },
        { text: "settings.clientSeverityLevel.instructions.step6" },
    ],
};

export const DescriptionSection = () => {
    return (
        <Stack gap={"XS"} mb="M" justifyContent="space-around">
            <CollapsibleSections sectionConfig={sectionConfig} />
            <CollapsibleSections sectionConfig={sectionConfig2} />
        </Stack>
    );
};
