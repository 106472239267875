import { EmptyState } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { StackStyled } from "./EmptyResults.styles";

export const EmptyResults = () => {
    const { t } = useTranslation();

    return (
        <StackStyled data-testid="settings-csl-empty-state">
            <EmptyState
                icon="Search"
                title={t("settings.clientSeverityLevel.EmptyResults.Title")}
                subtitle={t("settings.clientSeverityLevel.EmptyResults.Subtitle")}
            />
        </StackStyled>
    );
};
