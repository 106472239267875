import { sum, union } from "lodash";
import { useMemo } from "react";
import { useSqlQuery } from "src/sql/hooks";
import { useFilteredSites } from "src/store/insights/FilterHooks";

import { getReportsCountQuery, parseReportsCountResult } from "../queries";
import type { ReportsCountData } from "../types";
import { useDeviationsSiteSummary } from "./useDeviationsSiteSummary";

const useReportsCountQuery = (siteIds: string[], startDate: Date, endDate: Date) => {
    const queryParams = useMemo(() => ({ siteIds, startDate, endDate }), [siteIds, startDate, endDate]);
    const { isLoading, queryResult } = useSqlQuery(getReportsCountQuery, parseReportsCountResult, queryParams);

    return { siteReportsCountMap: queryResult, isLoading };
};

export const useCombinedReportsAndDeviations = (startDate: Date, endDate: Date) => {
    const { siteIds } = useFilteredSites();
    const { siteReportsCountMap, isLoading: isLoadingReports } = useReportsCountQuery(siteIds, startDate, endDate);
    const { sitesDeviationsCountMap = {}, isLoading: isLoadingDeviations } = useDeviationsSiteSummary(siteIds, startDate, endDate);

    const totalReports = sum(Object.values(siteReportsCountMap || {}));
    const totalDeviations = Object.values(sitesDeviationsCountMap || {})?.reduce((total, categoriesMap) => total + sum(Object.values(categoriesMap)), 0);
    const sitesCount = union(Object.keys(siteReportsCountMap || {}), Object.keys(sitesDeviationsCountMap || {})).length;

    return {
        isLoading: isLoadingReports || isLoadingDeviations,
        reportsCountData: {
            sitesCount,
            reportsCount: totalReports + totalDeviations,
        } satisfies ReportsCountData,
    };
};
