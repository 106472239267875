import { DamageReportType, IHighlightsCard, IncidentsLocationsType, ITrendingEvent, SummaryViewMode } from "./InsightsModel";
import {
    INCIDENTS_LOCATIONS_REQUEST,
    INCIDENTS_LOCATIONS_SUCCESS,
    INSIGHTS_CRITICAL_CARDS_NEXT_PAGE_SUCCESS,
    INSIGHTS_CRITICAL_CARDS_REQUEST,
    INSIGHTS_CRITICAL_CARDS_REQUEST_NEXT_PAGE,
    INSIGHTS_CRITICAL_CARDS_RESET,
    INSIGHTS_CRITICAL_CARDS_SUCCESS,
    INSIGHTS_DAMAGE_REPORTS_REQUEST,
    INSIGHTS_DAMAGE_REPORTS_SUCCESS,
    INSIGHTS_DB_FAILURE,
    INSIGHTS_DB_REQUEST,
    INSIGHTS_DB_SUCCESS,
    INSIGHTS_DBS_RESET,
    INSIGHTS_FILTER_APPLY_PERSISTED_FILTERS,
    INSIGHTS_FILTER_SELECT_DATE_RANGE,
    INSIGHTS_FILTER_SELECT_LOCATIONS_FILTER,
    INSIGHTS_MARK_CLIENT_VERIFIED,
    INSIGHTS_SET_INCIDENT_MATRIX_CATEGORY,
    INSIGHTS_SET_INCIDENT_MATRIX_IS_EXPANDED,
    INSIGHTS_SET_INCIDENT_MATRIX_LEVEL,
    INSIGHTS_SET_SELECTED_TAB,
    INSIGHTS_SET_SUMMARY_VIEW_MODE,
    INSIGHTS_TRENDING_EVENTS_FAILURE,
    INSIGHTS_TRENDING_EVENTS_REQUEST,
    INSIGHTS_TRENDING_EVENTS_SUCCESS,
} from "./types";

const loadDb = (
    dbId: string,
): {
    type: string;
    meta: { dbId: string };
} => ({
    type: INSIGHTS_DB_REQUEST,
    meta: { dbId },
});
const loadDbSuccess = (
    dbId: string,
): {
    type: string;
    meta: { dbId: string };
} => ({
    type: INSIGHTS_DB_SUCCESS,
    meta: { dbId },
});
const loadDbFailure = (
    dbId: string,
    error: unknown,
): {
    type: string;
    meta: { dbId: string };
    payload: unknown;
} => ({
    type: INSIGHTS_DB_FAILURE,
    payload: error,
    meta: { dbId },
});
const resetDatabases = (): {
    type: string;
} => ({
    type: INSIGHTS_DBS_RESET,
});

const selectLocations = (selectedRegions: string[]) => ({
    type: INSIGHTS_FILTER_SELECT_LOCATIONS_FILTER,
    payload: {
        selectedRegions,
    },
});

const selectDateRange = (selectedStartDate: Date, selectedEndDate: Date) => ({
    type: INSIGHTS_FILTER_SELECT_DATE_RANGE,
    payload: {
        selectedStartDate,
        selectedEndDate: selectedEndDate,
    },
});

const applyPersistedFilters = (
    selectedRegions: string[],
    selectedStartDate: Date,
    selectedEndDate: Date,
): {
    type: string;
    payload: {
        selectedRegions: string[];
        selectedStartDate: Date;
        selectedEndDate: Date;
    };
} => ({
    type: INSIGHTS_FILTER_APPLY_PERSISTED_FILTERS,
    payload: {
        selectedRegions,
        selectedStartDate,
        selectedEndDate,
    },
});

const requestDamageReports = (
    key: string,
): {
    type: string;
    payload: string;
} => ({
    type: INSIGHTS_DAMAGE_REPORTS_REQUEST,
    payload: key,
});

const requestIncidentsLocations = (): {
    type: string;
} => ({
    type: INCIDENTS_LOCATIONS_REQUEST,
});

const damageReportsSuccess = (
    damageReports: DamageReportType,
    key: string,
): {
    type: string;
    payload: { damageReports: DamageReportType; key: string };
} => ({
    type: INSIGHTS_DAMAGE_REPORTS_SUCCESS,
    payload: { damageReports, key },
});

const incidentsLocationsSuccess = (
    incidentsLocations: IncidentsLocationsType[],
): {
    type: string;
    payload: IncidentsLocationsType[];
} => ({
    type: INCIDENTS_LOCATIONS_SUCCESS,
    payload: incidentsLocations,
});

const requestTrendingEvents = (): {
    type: string;
} => ({
    type: INSIGHTS_TRENDING_EVENTS_REQUEST,
});

const trendingEventsSuccess = (
    events: ITrendingEvent[],
): {
    type: string;
    payload: ITrendingEvent[];
} => ({
    type: INSIGHTS_TRENDING_EVENTS_SUCCESS,
    payload: events,
});

const trendingEventsFailure = (): {
    type: string;
} => ({
    type: INSIGHTS_TRENDING_EVENTS_FAILURE,
});

const criticalCardsRequest = (): {
    type: string;
} => ({
    type: INSIGHTS_CRITICAL_CARDS_REQUEST,
});

const criticalCardsRequestNextPage = (): {
    type: string;
} => ({
    type: INSIGHTS_CRITICAL_CARDS_REQUEST_NEXT_PAGE,
});

const criticalCardsSuccess = (
    cards: IHighlightsCard[],
    queryKey: string,
): {
    type: string;
    payload: { cards: IHighlightsCard[]; queryKey: string };
} => ({
    type: INSIGHTS_CRITICAL_CARDS_SUCCESS,
    payload: { cards, queryKey },
});

const criticalCardsNextPageSuccess = (
    cards: IHighlightsCard[],
): {
    type: string;
    payload: IHighlightsCard[];
} => ({
    type: INSIGHTS_CRITICAL_CARDS_NEXT_PAGE_SUCCESS,
    payload: cards,
});

const criticalCardsReset = (): {
    type: string;
} => ({
    type: INSIGHTS_CRITICAL_CARDS_RESET,
});

const setSelectedTab = (number: number) => {
    return { type: INSIGHTS_SET_SELECTED_TAB, payload: number };
};

const setSummaryViewMode = (mode: SummaryViewMode) => {
    return { type: INSIGHTS_SET_SUMMARY_VIEW_MODE, payload: mode };
};

const confirmSummaryClientVerification = (hasData: boolean) => {
    return { type: INSIGHTS_MARK_CLIENT_VERIFIED, payload: hasData };
};

const setIncidentMatrixCategory = (category: string) => {
    return { type: INSIGHTS_SET_INCIDENT_MATRIX_CATEGORY, payload: category };
};

const setIncidentMatrixLevel = (level: number) => {
    return { type: INSIGHTS_SET_INCIDENT_MATRIX_LEVEL, payload: level };
};

const setIncidentMatrixIsExpanded = (isExpanded: boolean) => {
    return { type: INSIGHTS_SET_INCIDENT_MATRIX_IS_EXPANDED, payload: isExpanded };
};

export default {
    applyPersistedFilters,
    confirmSummaryClientVerification,
    criticalCardsNextPageSuccess,
    criticalCardsRequest,
    criticalCardsRequestNextPage,
    criticalCardsReset,
    criticalCardsSuccess,
    damageReportsSuccess,
    incidentsLocationsSuccess,
    loadDb,
    loadDbFailure,
    loadDbSuccess,
    requestDamageReports,
    requestIncidentsLocations,
    requestTrendingEvents,
    resetDatabases,
    selectDateRange,
    selectLocations,
    setSelectedTab,
    setSummaryViewMode,
    trendingEventsFailure,
    trendingEventsSuccess,
    setIncidentMatrixCategory,
    setIncidentMatrixLevel,
    setIncidentMatrixIsExpanded,
};
