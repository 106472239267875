import { useMemo } from "react";
import { useFilteredSites } from "src/store/insights/FilterHooks";

import { SummaryPeriod, ToursIndicators } from "../types";
import { useTourSessionsPerSite } from "./useTourSessionsPerSite";

export const useReducedIndicatorValues = (period: SummaryPeriod) => {
    const { siteIds } = useFilteredSites();
    const { isLoading, tourSessionsMap } = useTourSessionsPerSite(siteIds, period.start, period.end);

    const toursStats: ToursIndicators = useMemo(() => {
        const emptyIndicatorsValues: ToursIndicators = { completedTours: 0, uncompletedTours: 0 };

        if (tourSessionsMap) {
            return Object.values(tourSessionsMap).reduce(
                ({ uncompletedTours, completedTours }, { completedCount, incompleteCount }) => ({
                    completedTours: completedTours + completedCount,
                    uncompletedTours: uncompletedTours + incompleteCount,
                }),
                emptyIndicatorsValues,
            );
        }

        return emptyIndicatorsValues;
    }, [tourSessionsMap]);

    return { values: toursStats, isLoading };
};
