import { Box, Breakpoints } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const BoxStyled = styled(Box)<{ isEditButtonVisible: boolean }>`
    max-width: ${({ isEditButtonVisible }) => (isEditButtonVisible ? "85%" : "100%")};

    @media (max-width: ${Breakpoints.XS}) {
        max-width: 100%;
        button {
            max-width: 100%;
        }
        button > div {
            text-overflow: ellipsis;
            overflow: hidden;
            overflow-wrap: break-word;
        }
    }
`;
