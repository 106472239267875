import { Breakpoints, Button, ButtonText, Palette, Spacing, Text, useClickOutside, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useCallback, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { IMultiListItem } from "src/components/shared/CheckboxList/CheckboxListItem.types";
import { MultiDropdown } from "src/components/shared/MultiDropdown/MultiDropdown";
import { CheckboxListStyled, DropdownListWrapper } from "src/components/shared/MultiDropdown/MultiDropdown.styled";
import { ResponsiveOverlay } from "src/components/shared/Overlays/ResponsiveOverlay";
import { useOnEscapeEventListener } from "src/hooks/WebAccessibilityHooks";
import { QaProps } from "src/models";
import { flexColumnAlignCenter } from "src/styles/templates/Flex";
import styled from "styled-components";

import { ApplyFilterButton, Dropdown } from "./TextCheckboxDropdown.styled";

const dropDownListWidth = "250px";

const StyledDropdownWrapper = styled(Dropdown)<{ active: boolean }>`
    width: auto;
    @media (max-width: ${Breakpoints.XS}) {
        position: static;
    }
    ${DropdownListWrapper} {
        @media (min-width: ${Breakpoints.XS}) {
            padding-bottom: 0;
            width: ${dropDownListWidth};
            border-radius: ${Spacing.M}px;
            top: 30px;
        }
        @media (max-width: ${Breakpoints.XS}) {
            border-top-right-radius: ${Spacing.M}px;
            border-top-left-radius: ${Spacing.M}px;
            background-color: ${Palette.Navy600};
        }
    }
`;

const StyledMultiDropdownWrapper = styled.div`
    position: absolute;
    ${flexColumnAlignCenter};
    justify-content: center;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 1;
    background-color: ${Palette.Navy600};
    padding: ${Spacing.S}px ${Spacing.XS}px;
    border-top-right-radius: ${Spacing.M}px;
    border-top-left-radius: ${Spacing.M}px;

    ${ApplyFilterButton} {
        background-color: ${Palette.Navy600};
        padding-top: 0;
    }
    ${DropdownListWrapper} {
        margin: ${Spacing.XS}px 0;
    }
    ${CheckboxListStyled} {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        gap: ${Spacing.S}px;
    }
`;

type Props = QaProps & {
    items: IMultiListItem[];
    selectedItems: string[];
    onChange: (val: string) => void;
    children?: string;
};

export const TextCheckboxDropdown = ({ onChange, items, selectedItems, children, ...otherProps }: Props) => {
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const [isActive, setIsActive] = useState<boolean>(false);
    const { nodeRef } = useClickOutside<HTMLDivElement>(() => {
        if (isActive) {
            setIsActive(false);
        }
    });

    const handleChange = useCallback(
        (item: IMultiListItem) => {
            onChange(item.value);
        },
        [onChange],
    );

    const toggleDropdown = () => {
        setIsActive(!isActive);
    };

    const hideDropdown = () => {
        setIsActive(false);
    };

    useOnEscapeEventListener(hideDropdown, nodeRef, isActive);

    const dropdownWrapperProps = isMobile ? { ref: nodeRef, active: true } : { ref: nodeRef, active: isActive };
    const multiDropdownWrapperProps = isMobile ? { ref: nodeRef } : {};

    const renderMobileDropdown = () =>
        createPortal(
            <StyledMultiDropdownWrapper {...multiDropdownWrapperProps}>
                <Text micro bold color="secondary">
                    {t("insights.exceptionTrend.categoryFilter.label")}
                </Text>
                <MultiDropdown showSelected={false} items={items} selectedItems={selectedItems} onSelectionChange={handleChange} />
                <ApplyFilterButton>
                    <Button onClick={toggleDropdown} mode="outlined">
                        {t("common.cancel")}
                    </Button>
                </ApplyFilterButton>
            </StyledMultiDropdownWrapper>,
            document.body,
        );

    return (
        <StyledDropdownWrapper {...otherProps} {...dropdownWrapperProps}>
            <ButtonText color="secondary" direction="row-reverse" icon={isActive ? "ArrowUp" : "ArrowDown"} iconSize="S" onClick={toggleDropdown}>
                {children ?? t("insights.exceptionTrend.categoryFilter.label")}
            </ButtonText>
            {isActive && isMobile && <ResponsiveOverlay breakpoint={Breakpoints.XS}>{renderMobileDropdown()}</ResponsiveOverlay>}
            {isActive && !isMobile && <MultiDropdown showSelected={false} items={items} selectedItems={selectedItems} onSelectionChange={handleChange} />}
        </StyledDropdownWrapper>
    );
};
