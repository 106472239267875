import { PageTabs } from "src/components/shared/PageTabs";
import { PageNames } from "src/models/tracking/pageView";
import { useTrackPageVisit } from "src/store/tracking/TrackingHooks";

import { SETTINGS_TABS } from "./Settings.constants";
import { WrapperStyled } from "./Settings.styles";
import { SettingsHeader } from "./SettingsHeader/SettingsHeader";

export const Settings = () => {
    useTrackPageVisit(PageNames.Settings);

    return (
        <WrapperStyled>
            <SettingsHeader />
            <PageTabs tabs={SETTINGS_TABS} />
        </WrapperStyled>
    );
};
