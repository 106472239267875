import { ButtonIcon, Icon } from "@secuis/ccp-react-components";
import { StackProps } from "@secuis/ccp-react-components/dist/types/styles/stack/Stack";
import { forwardRef, PropsWithChildren, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ContainerStyled, ContentWrapperStyled, DraggingAddonStyled, ToolBarStyled, TooltipStyled, WrapperStyled } from "./DraggableWidgetWrapper.styles";

type Props = PropsWithChildren<
    {
        widgetId: string;
        onRemove?: (id: string) => void;
        onAdd?: (id: string) => void;
        editMode?: boolean;
        isDisabled?: boolean;
        isDragged?: boolean;
    } & StackProps
>;

export const DraggableWidgetWrapper = forwardRef<HTMLDivElement, Props>(
    ({ children, isDragged, editMode, isDisabled, widgetId, onRemove, onAdd, ...otherProps }, ref) => {
        const isDragActive = editMode && isDragged;
        const { t } = useTranslation();
        const tooltipAnchorClass = "dragged-widget";
        const handleAddWidget = useCallback(() => {
            onAdd?.(widgetId);
        }, [widgetId, onAdd]);
        const handleRemoveWidget = useCallback(() => {
            onRemove?.(widgetId);
        }, [widgetId, onRemove]);

        return (
            <ContainerStyled ref={ref} shaking={editMode && !(isDragged || isDisabled)} {...otherProps}>
                <WrapperStyled isEditable={editMode} isDragged={isDragged} isDisabled={isDisabled}>
                    {isDragged && (
                        <DraggingAddonStyled>
                            <TooltipStyled anchorSelect={`.${tooltipAnchorClass}`} isOpen={true}>
                                {t("common.draggableGrid.draggingTooltip")}
                            </TooltipStyled>
                            <Icon variant="Moving" className={tooltipAnchorClass} size="XXL" />
                        </DraggingAddonStyled>
                    )}
                    {editMode && (
                        <ToolBarStyled>
                            {isDisabled ? (
                                <ButtonIcon icon="Plus" onClick={handleAddWidget} iconSize="M" mode="stateless" />
                            ) : (
                                <ButtonIcon icon="Close" onClick={handleRemoveWidget} iconSize="M" mode="stateless" />
                            )}
                        </ToolBarStyled>
                    )}

                    <ContentWrapperStyled isDisabled={isDisabled} isBlurred={isDragActive}>
                        {children}
                    </ContentWrapperStyled>
                </WrapperStyled>
            </ContainerStyled>
        );
    },
);

DraggableWidgetWrapper.displayName = "DraggableWidgetWrapper";
