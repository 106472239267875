import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMemoizedDependency } from "src/hooks/useMemoizedDependency";
import InsightsActions from "src/store/insights/InsightsActions";
import InsightsSelectors from "src/store/insights/InsightsSelectors";

type ToggleVisibleItemsProps<T> = {
    items: T[];
    maxVisibleItems: number;
};

export const useToggleVisibleItems = <T>({ items, maxVisibleItems }: ToggleVisibleItemsProps<T>) => {
    const dispatch = useDispatch();
    const [visibleItems, setVisibleItems] = useState<T[]>(items.slice(0, maxVisibleItems));
    const memoizedItems = useMemoizedDependency(items);
    const isExpanded = useSelector(InsightsSelectors.getIncidentMatrixIsExpanded);

    useEffect(() => {
        setVisibleItems(isExpanded ? memoizedItems : memoizedItems.slice(0, maxVisibleItems));
    }, [memoizedItems, maxVisibleItems, isExpanded]);

    const showMoreItems = () => {
        dispatch(InsightsActions.setIncidentMatrixIsExpanded(true));
    };
    const showLessItems = () => {
        dispatch(InsightsActions.setIncidentMatrixIsExpanded(false));
    };

    return {
        visibleItems,
        showMoreItems,
        showLessItems,
    };
};
