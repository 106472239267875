import { Breakpoints, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { TileContainer } from "src/components/Insights/common/InsightTile";
import { useInsightsPageContext } from "src/pages/Insights/InsightsPageProvider";
import InsightsActions from "src/store/insights/InsightsActions";

import { useCategoryInfo } from "../../shared/hooks";
import { EmptyState, IncidentColorScale, Loader, MatrixHeader, Pagination, SitesLegend, ToggleVisibleItems } from "./components";
import { getCategoryIncidentCount } from "./helpers";
import { useColumns, useMatrixData, usePagination, useToggleVisibleItems } from "./hooks";
import { TableStyled } from "./IncidentMatrix.styles";
import { DataItem, IncidentMatrixProps } from "./IncidentMatrix.types";

const MAX_COLUMNS_COUNT_TO_ENABLE_MAX_WIDTH = 4;
const MAX_INITIAL_ITEMS_COUNT = 5;
const MAX_ITEMS_COUNT_WITHOUT_SCROLL = 10;

export const IncidentMatrix = ({ categoryKey = null }: IncidentMatrixProps) => {
    const { isLoading, data, categories, maxIncidentCount } = useMatrixData(categoryKey);
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const isLargeScreen = !useHasMaxWidth(Breakpoints.L);
    const categoriesPerPage = isMobile ? 2 : 4;

    const { level: categoryLevel } = useCategoryInfo(categoryKey);

    const { visibleItems, showMoreItems, showLessItems } = useToggleVisibleItems<DataItem>({
        items: data,
        maxVisibleItems: MAX_INITIAL_ITEMS_COUNT,
    });

    const nonEmptyCategories = categories.filter((category) => getCategoryIncidentCount(visibleItems, category) > 0);

    const {
        totalPages,
        currentItems: visibleCategories,
        hiddenItems: hiddenCategories,
        currentPage,
        showPreviousPage,
        showNextPage,
    } = usePagination({
        items: nonEmptyCategories,
        itemsPerPage: categoriesPerPage,
    });

    const isOtherColumnEnabled = isLargeScreen && hiddenCategories.length > 0;

    const { columnsData, columnsCount, rowsData } = useColumns({
        visibleCategories,
        hiddenCategories,
        items: visibleItems,
        maxIncidentCount,
        isOtherColumnEnabled,
    });

    const isColMaxWidthEnabled = columnsData.length <= MAX_COLUMNS_COUNT_TO_ENABLE_MAX_WIDTH && isLargeScreen;
    const { isFilterActive } = useInsightsPageContext();

    const siteLegendItems = visibleItems.map((item) => (item.site && "name" in item.site ? item.site.name : null));

    useEffect(() => {
        dispatch(InsightsActions.setIncidentMatrixLevel(categoryLevel));
    }, [categoryLevel, dispatch]);

    return (
        <TileContainer data-testid="matrix-table-wrapper">
            <MatrixHeader title={t("incidentMatrix.header.title")} />
            {isLoading ? (
                <Loader />
            ) : data.length > 0 ? (
                <>
                    {nonEmptyCategories.length > categoriesPerPage && (
                        <Pagination currentPage={currentPage} totalPages={totalPages} showPreviousPage={showPreviousPage} showNextPage={showNextPage} />
                    )}

                    <TableStyled
                        data={rowsData}
                        columns={columnsData}
                        colMaxWidthEnabled={isColMaxWidthEnabled}
                        scrollable={rowsData.length > MAX_ITEMS_COUNT_WITHOUT_SCROLL}
                        rowGroupsEnabled={true}
                        isFilterActive={isFilterActive}
                        categoryColumnCount={columnsCount - 1}
                        isOtherColumnEnabled={isOtherColumnEnabled}
                    />
                    {isMobile && siteLegendItems.length > 0 && <SitesLegend sites={siteLegendItems} />}
                    <Stack justifyContent={data.length > MAX_INITIAL_ITEMS_COUNT ? "space-between" : "flex-end"} mt="M" flexWrap="wrap" gap="S">
                        {data.length > MAX_INITIAL_ITEMS_COUNT && (
                            <ToggleVisibleItems
                                items={rowsData}
                                maxItems={MAX_INITIAL_ITEMS_COUNT}
                                showMoreItems={showMoreItems}
                                showLessItems={showLessItems}
                                showMoreItemsText={t("incidentMatrix.table.showMore")}
                                showLessItemsText={t("incidentMatrix.table.showLess")}
                            />
                        )}

                        <IncidentColorScale />
                    </Stack>
                </>
            ) : (
                <EmptyState />
            )}
        </TileContainer>
    );
};
