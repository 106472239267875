import { ButtonText, Stack, Text } from "@secuis/ccp-react-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { StackStyled, UlStyled } from "./CollapsibleSection.styles";
import { ListItem, SectionConfig } from "./DescriptionSection";

interface CollapsibleSectionsProps {
    sectionConfig: SectionConfig;
}

export const CollapsibleSections = ({ sectionConfig }: CollapsibleSectionsProps) => {
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleCollapse = () => {
        setIsExpanded(!isExpanded);
    };

    const renderList = (items: ListItem[]) => (
        <UlStyled>
            {items.map((item, index) => (
                <li key={index}>
                    <Text>{t(item.text)}</Text>
                    {item.subitems && renderList(item.subitems)}
                </li>
            ))}
        </UlStyled>
    );

    return (
        <StackStyled direction="column" padding="S" isExpanded={isExpanded} data-testid="collapsible-section">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Text bold>{t(sectionConfig.title)}</Text>
                <ButtonText micro icon={isExpanded ? "ArrowUp" : "ArrowDown"} onClick={toggleCollapse} direction="row-reverse">
                    {isExpanded ? t("common.showLess") : t("common.showMore")}
                </ButtonText>
            </Stack>
            {isExpanded && (
                <Stack mt="XS" data-testid="collapsible-content">
                    {renderList(sectionConfig.listItems)}
                </Stack>
            )}
        </StackStyled>
    );
};
