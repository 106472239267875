import { Palette, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const StackStyled = styled(Stack)<{ isExpanded: boolean }>`
    border-radius: 4px;
    background-color: ${Palette.Navy700};
    height: ${(props) => (props.isExpanded ? "auto" : "56px")};
    min-height: fit-content;
    flex-basis: 100%;
`;

export const UlStyled = styled.ul`
    list-style-type: disc;
    margin: 0;
`;
