import { Button, ButtonText, Headline, Icon, Stack } from "@secuis/ccp-react-components";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addDays, formatExplicit, formatToRawDate, subDays } from "src/helpers/date";
import { scheduleSlice } from "src/store/schedule/scheduleSlice";
import { getSelectedDate } from "src/store/schedule/selectors";
import { useDebouncedCallback } from "use-debounce";

import { Tooltip } from "../shared/Tooltip";
import { ScheduleDateFilter } from "./ScheduleDateFilter";

type Props = {
    locationId: string;
    getTasks: (date: string, locationId: string) => void;
    showDatepicker?: boolean;
    tooltip?: string;
};

export const ScheduleFilters = ({ locationId, getTasks, showDatepicker = false, tooltip }: Props) => {
    const { t } = useTranslation();
    const date = useSelector(getSelectedDate);
    const dispatch = useDispatch();

    const setDate = useCallback(
        (seletedDate: Date) => {
            dispatch(scheduleSlice.actions.changeDate(seletedDate));
        },
        [dispatch],
    );

    const [getTasksDebounced] = useDebouncedCallback((date: Date, locationId: string) => getTasks(formatToRawDate(date), locationId), 500);

    useEffect(() => {
        getTasksDebounced(date, locationId);
    }, [locationId, date]);

    const setTodaysDate = useCallback(() => {
        setDate(new Date());
    }, []);

    const setPreviousDate = useCallback(() => {
        setDate(subDays(date, 1));
    }, [date]);

    const setNextDate = useCallback(() => {
        setDate(addDays(date, 1));
    }, [date]);

    return (
        <Stack marginVertical="S" flexWrap="wrap" gap={["M", "S", "XS"]}>
            <Button mode="outlined" fitContent onClick={setTodaysDate}>
                {t("common.date.today")}
            </Button>
            <Stack alignItems="center" gap="XS">
                <Stack flex="1" alignItems="center" gap={["S", "S", "XS"]}>
                    <ButtonText onClick={setPreviousDate} icon="ArrowBack" iconSize="XL" />
                    <ButtonText onClick={setNextDate} icon="ArrowForward" iconSize="XL" />
                    <Headline bold>{formatExplicit(date, "eee, PPP")}</Headline>
                </Stack>
                {tooltip && (
                    <Stack paddingTop="XXXS">
                        <Tooltip message={tooltip}>
                            <Icon size="S" variant="Info" />
                        </Tooltip>
                    </Stack>
                )}
            </Stack>
            {showDatepicker && (
                <>
                    <Stack flex="1" />
                    <ScheduleDateFilter />
                </>
            )}
        </Stack>
    );
};
