import { Tab } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { OutletWrapperStyled, TabsStyled, WrapperStyled } from "./PageTabs.styles";

type Props = {
    tabs: { label: string; url: string }[];
};

export const PageTabs = ({ tabs }: Props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const activeTabIndex = tabs.findIndex((tab) => location.pathname.includes(tab.url));

    const onTabChange = (tabIndex: number) => {
        navigate(tabs[tabIndex].url);
    };

    const hasMultipleTabs = tabs.length > 1;

    return (
        <WrapperStyled>
            {hasMultipleTabs && (
                <TabsStyled value={activeTabIndex} onChange={onTabChange} role="tablist">
                    {tabs.map((tab) => {
                        return <Tab key={tab.label} label={t(tab.label)} />;
                    })}
                </TabsStyled>
            )}
            <OutletWrapperStyled mv={hasMultipleTabs ? "L" : "0"} ph={["L", "M", "S"]}>
                <Outlet />
            </OutletWrapperStyled>
        </WrapperStyled>
    );
};
