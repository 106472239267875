import { Icon, IconType, Stack, Text, Title } from "@secuis/ccp-react-components";

type Props = {
    label: string;
    title?: string;
    icon?: IconType;
    showRedirectIcon?: boolean;
    className?: string;
};

export const ValueTileStatic = ({ label, title, icon, showRedirectIcon, className }: Props) => (
    <Stack className={className} pv="S" ph={["S", "S", "0"]} direction="column" gap="XXS">
        {title && (
            <Stack justifyContent="space-between">
                <Stack alignItems="center" gap="XS">
                    {icon && <Icon color="secondary" variant={icon} />}
                    <Text bold color="secondary">
                        {title}
                    </Text>
                </Stack>
                {showRedirectIcon && <Icon size="L" color="secondary" variant="ArrowForward" />}
            </Stack>
        )}

        <Stack direction="column" alignItems="flex-start" justifyContent="center" gap="XXS">
            <Stack alignItems="center" gap="XS">
                <Title color="primary">-</Title>
                <Text color="primary" small>
                    {label}
                </Text>
            </Stack>
        </Stack>
    </Stack>
);
