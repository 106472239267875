import { Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const WrapperStyled = styled(Stack).attrs({
    direction: "column",
    flex: 1,
})`
    min-height: 100vh;
    overflow: auto;
`;
