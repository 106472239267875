enum SeverityIconVariant {
    PriorityInfo = "PriorityInfo",
    PriorityLow = "PriorityLow",
    PriorityModerate = "PriorityModerate",
    PriorityHigh = "PriorityHigh",
    PrioritySevere = "PrioritySevere",
}

const SEVERITY_ICONS: Record<number, SeverityIconVariant> = {
    0: SeverityIconVariant.PriorityInfo,
    1: SeverityIconVariant.PriorityLow,
    2: SeverityIconVariant.PriorityModerate,
    3: SeverityIconVariant.PriorityHigh,
    4: SeverityIconVariant.PrioritySevere,
};

export const getSeverityIconVariant = (severityLevel: number): SeverityIconVariant => SEVERITY_ICONS[severityLevel] || SeverityIconVariant.PriorityLow;
