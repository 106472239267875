import { PersistedState } from "redux-persist";

import { RequestStatus } from "../RequestStatus";

export const REPORT_CATEGORIES_REQUEST = "@@reportCategories/REPORT_CATEGORIES_REQUEST";
export const REPORT_CATEGORIES_SUCCESS = "@@reportCategories/REPORT_CATEGORIES_SUCCESS";
export const REPORT_CATEGORIES_FAILURE = "@@reportCategories/REPORT_CATEGORIES_FAILURE";

export interface IReportCategoryLevel3 {
    code: string;
    key: string;
    severityLevel: string;
}
export interface IReportCategoryLevel2 {
    key: string;
    level3Categories: IReportCategoryLevel3[];
}
export interface IReportCategoryLevel1 {
    key: string;
    level2Categories: IReportCategoryLevel2[];
}

export interface IReportCategoriesState extends PersistedState {
    status: RequestStatus;
    reportCategoriesLevel1: IReportCategoryLevel1[];
}
