import { Background, ButtonText, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import { ReactNode, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

const StackStyled = styled(Stack).attrs({
    paddingHorizontal: "S",
    paddingTop: "S",
    direction: "column",
})`
    border-radius: 4px;
    background-color: ${Background.overlay};
`;

const ellipsisStyles = css`
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    white-space: normal;
    display: -webkit-box;
`;

const CONTENT_GAP = Spacing.XS;

const CollapsableWrapper = styled(Text)<{ collapsable: boolean; elMaxHeight?: string; animationEnabled?: boolean; isExpanded?: boolean }>`
    transition: ${({ animationEnabled }) => (animationEnabled ? "height 500ms ease-in-out" : null)};
    overflow: ${({ collapsable }) => (collapsable ? "hidden" : null)};
    ${({ isExpanded }) => (!isExpanded ? ellipsisStyles : null)};
    height: ${({ elMaxHeight }) => (elMaxHeight ? elMaxHeight : null)};
    margin-bottom: ${Spacing.S}px;
    font-size: 0.875rem;

    /* space between header and text when expanded */
    /* invisible when collapsed */
    &::before {
        content: "";
        display: block;
        height: ${CONTENT_GAP}px;
    }
`;

type Props = {
    Header: ReactNode;
    text: string;
    visibleCollapsedTextLinesCount?: number;
};

export const ExpansionTextPanel = ({ Header, text, visibleCollapsedTextLinesCount = 0 }: Props) => {
    const { t } = useTranslation();
    const textWrapperRef = useRef<HTMLDivElement>(null);
    const [isExpanded, setIsExpanded] = useState<boolean | null>(null);
    const [maxHeight, setMaxHeight] = useState<string>("0px");

    const toggle = () => {
        setIsExpanded((isExpanded) => !isExpanded);
    };

    useLayoutEffect(() => {
        if (textWrapperRef.current) {
            const scrollHeight = textWrapperRef.current.scrollHeight;
            const lineHeight = document.defaultView.getComputedStyle(textWrapperRef.current).lineHeight;
            const collapsedHeight = visibleCollapsedTextLinesCount ? visibleCollapsedTextLinesCount * parseInt(lineHeight) + CONTENT_GAP : 0;

            setMaxHeight(isExpanded ? `${scrollHeight}px` : `${collapsedHeight}px`);
        }
    }, [textWrapperRef, visibleCollapsedTextLinesCount, isExpanded]);

    return (
        <StackStyled>
            <Stack justifyContent="space-between" alignItems="center">
                {Header}
                <ButtonText micro onClick={toggle} icon={isExpanded ? "ArrowUp" : "ArrowDown"}>
                    {isExpanded ? t("common.showLess") : t("common.showMore")}
                </ButtonText>
            </Stack>
            <CollapsableWrapper ref={textWrapperRef} isExpanded={isExpanded} collapsable animationEnabled={isExpanded !== null} elMaxHeight={maxHeight}>
                {text}
            </CollapsableWrapper>
        </StackStyled>
    );
};
