import { Breakpoints, ButtonIcon, IconType, Palette, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const ScrollTopButton = styled(ButtonIcon).attrs<{ icon?: IconType; mode?: "contained" | "outlined" | "stateless" }>({
    icon: "ArrowUp",
    mode: "contained",
})`
    position: absolute;
    bottom: ${Spacing.L}px;
    right: ${Spacing.XXL}px;

    & svg {
        color: ${Palette.Navy800};
    }

    &:focus-visible {
        border-radius: 50%;
    }

    @media (min-width: ${Breakpoints.XS}) and (max-width: ${Breakpoints.S}) {
        bottom: ${Spacing.M}px;
        right: ${Spacing.XXL}px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        bottom: ${Spacing.S}px;
        right: ${Spacing.L}px;
    }
`;
