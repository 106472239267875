import { Breakpoints, Icon, useHasMaxWidth } from "@secuis/ccp-react-components";
import { Tooltip } from "src/components/shared/Tooltip";
import { REPORT_CATEGORY_ICON_MAP } from "src/pages/Insights/shared/constants";

type Props = {
    category: string;
    tooltipMessage: string;
};

const CategoryIcon = ({ category, tooltipMessage }: Props) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const icon = <Icon data-testid={category} variant={REPORT_CATEGORY_ICON_MAP[category]} />;

    return isMobile ? icon : <Tooltip message={tooltipMessage}>{icon}</Tooltip>;
};

export default CategoryIcon;
