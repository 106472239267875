import { keyBy, mapValues, maxBy } from "lodash";
import { Layout, Layouts } from "react-grid-layout";

import { GRID_WIDGETS } from "../Summary.constants";
import { DEFAULT_COLUMNS_PER_BREAKPOINT, GRID_BREAKPOINTS, LAYOUT_BREAKPOINTS } from "./DraggableGrid.constants";
import { ColumnsPerBreakpoint, GridItem, ItemPlacement, ScreenWidthVariant } from "./DraggableGrid.types";

export const getColumnsSetup = (columns: number | ColumnsPerBreakpoint): ColumnsPerBreakpoint => {
    let setup = DEFAULT_COLUMNS_PER_BREAKPOINT;

    if (columns) {
        if (typeof columns === "number") {
            setup = mapValues(setup, () => columns);
        } else {
            setup = columns;
        }
    }

    return setup;
};

export const prepareResponsiveLayoutConfig = (widgets: GridItem[], placement: ItemPlacement[]): Layouts => {
    const widgetsMap = keyBy(widgets, "id");

    return LAYOUT_BREAKPOINTS.reduce(
        (acc, breakpoint) => ({
            [breakpoint]: placement.map(({ id, ...item }) => {
                const widget = widgetsMap[id];
                const width = widget?.responsiveSizing?.[breakpoint]?.width || widget.width || 1;
                const height = widget?.responsiveSizing?.[breakpoint]?.height || widget.height || 1;

                return { i: id, w: width, h: height, ...item };
            }),
            ...acc,
        }),
        {},
    );
};

export const calculateNewWidgetPosition = (layout: Layout[], widget: Layout, columnsCount: number) => {
    const lastItemY = maxBy(layout, "y") || { x: 0, y: 0, w: 0 };
    const lastRow = layout.filter((item) => item.y === lastItemY.y);
    const lastItemX = maxBy(lastRow, "x") || { x: 0, y: 0, w: 0 };
    const widgetWidth = widget.w;
    const fitInLastLine = lastItemX.x + lastItemX.w + widgetWidth <= columnsCount;

    if (fitInLastLine) {
        const xPos = lastItemX.x + lastItemX.w;
        return { y: lastItemY.y, x: xPos };
    } else {
        return { x: 0, y: lastItemY.y + 1 };
    }
};

export const updateRemovedLayout = (baseLayout: Layout[], columns: Partial<Record<ScreenWidthVariant, number>>, breakpoint: ScreenWidthVariant): Layout[] => {
    return GRID_WIDGETS.filter((widget) => !baseLayout.some((item) => item.i === widget.id)).reduce((acc, widget) => {
        const widgetWidth = widget.responsiveSizing?.[breakpoint]?.width || widget.width || 1;
        const widgetHeight = widget.responsiveSizing?.[breakpoint]?.height || widget.height || 1;
        const removedWidget = {
            ...widget,
            w: widgetWidth,
            h: widgetHeight,
            i: widget.id,
        };

        return [
            ...acc,
            {
                ...removedWidget,
                ...calculateNewWidgetPosition(acc, removedWidget, columns[breakpoint]),
            },
        ];
    }, []);
};

export const getCurrentLayoutBreakpoint = (): ScreenWidthVariant => {
    const documentWidth = document.body.clientWidth;

    return LAYOUT_BREAKPOINTS.find((breakpoint) => documentWidth >= GRID_BREAKPOINTS[breakpoint]) ?? "xxs";
};
