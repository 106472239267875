import { Background, Palette, Spacing, Table } from "@secuis/ccp-react-components";
import { TableVirtuoso } from "react-virtuoso";
import styled from "styled-components";

export const WrapperStyled = styled.div<{ height?: number }>`
    width: 100%;
    height: ${({ height }) => (height ? `${height}px` : "100%")};
    border-radius: ${Spacing.XXS}px;
    overflow: auto;
`;

export const TableVirtuosoStyled = styled(TableVirtuoso)`
    background-color: ${Background.content};
    table {
        width: 100%;
    }

    tr {
        border-bottom: none;
        box-shadow: 0 1px 0 ${Palette.Navy300}4d;
    }

    ${Table.Th} {
        &:first-child {
            border-top-left-radius: ${Spacing.XXS}px;
            width: 60%;
        }
        &:last-child {
            border-top-right-radius: ${Spacing.XXS}px;
        }
    }
`;
