import { Background, Breakpoints, Spacing, Stack, Theme } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const StackStyled = styled(Stack).attrs({
    direction: "column",
    justifyContent: "center",
    alignItems: "center",
    pv: "XXL",
})`
    border-radius: ${Spacing.XXS}px;
    background: ${Background.content};

    @media only screen and (min-width: ${Breakpoints.L}) {
        height: fit-content;
    }

    div {
        color: ${Theme.primary};
    }
`;
