import { Badge, Text } from "@secuis/ccp-react-components";
import { TableCellProps } from "src/components/shared/Table/Table.types";
import { PatrolTourType } from "src/models/ReportModel";

type Props = TableCellProps<number, PatrolTourType>;

export const CheckpointsCell = ({ row }: Props) => {
    const total = row.checkpointsTotal || 0;
    const missedTotal = row.checkpointsMissedTotal || 0;

    if (!(total + missedTotal)) {
        return (
            <Text micro bold>
                -
            </Text>
        );
    }
    return <Badge label={`${Math.round(((row.checkpointsTotal - row.checkpointsMissedTotal) / row.checkpointsTotal) * 100)}%`} mode="outlined" />;
};
